import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const RepsIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.25 20.5c.69 0 1.25-.56 1.25-1.25v-14a1.25 1.25 0 0 0-2.5 0v14c0 .69.56 1.25 1.25 1.25Zm4.5 0c.69 0 1.25-.56 1.25-1.25v-14a1.25 1.25 0 0 0-2.5 0v14c0 .69.56 1.25 1.25 1.25Zm4.5 0c.69 0 1.25-.56 1.25-1.25v-14a1.25 1.25 0 0 0-2.5 0v14c0 .69.56 1.25 1.25 1.25Z"
    />
    <path
      fill="currentColor"
      d="M21.434 9.306a1.25 1.25 0 0 0-1.532-.884L3.208 12.894a1.25 1.25 0 0 0 .646 2.416l16.696-4.474a1.25 1.25 0 0 0 .884-1.53Z"
    />
  </StyledIcon>
)
