import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const Circle10Icon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#clip0)">
      <path d="M6 0C2.692 0 0 2.692 0 6c0 3.309 2.692 6 6 6 3.309 0 6-2.691 6-6 0-3.308-2.691-6-6-6Zm3.349 9.702A2.48 2.48 0 0 0 7 8H5a2.479 2.479 0 0 0-2.348 1.703A4.983 4.983 0 0 1 1 6c0-2.757 2.243-5 5-5s5 2.243 5 5c0 1.468-.64 2.787-1.651 3.702Z" />
      <path d="M6 2.5a2 2 0 0 0-2 2V5a2 2 0 0 0 4 0v-.5a2 2 0 0 0-2-2Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
