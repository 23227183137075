import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const WarningSignIcon = (props: IconProps) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.641 18.485 14.732 1.643a3.093 3.093 0 0 0-5.464 0L.359 18.485A3.079 3.079 0 0 0 3.092 23h17.816a3.08 3.08 0 0 0 2.733-4.515ZM12 20a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5h-2l-.5-8h3l-.5 8Z"
    />
  </StyledIcon>
)
