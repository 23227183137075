import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const Copy2Icon = (props: IconProps) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1 10v10h8v-2H3V2h13v5h2V0H1v10Zm10 6.5V24h12V9H11v7.5Zm10 0V22h-8V11h8v5.5Z"
      />
    </StyledIcon>
  )
}
