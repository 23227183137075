import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ChevronUpIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.565.98c-.244-.39-.886-.39-1.13 0L.1 6.313a.667.667 0 0 0 .566 1.02h6.666a.667.667 0 0 0 .566-1.02L4.565.98Z"
    />
  </StyledIcon>
)
