import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const BodyBuilderIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.415 5.71289L15.307 13.9999H8.69301L5.58501 5.71289L3.71201 6.41289L7.00001 15.1809V22.9999H17V15.1809L20.288 6.41489L18.415 5.71289Z"
        fill="currentColor"
      />
      <path
        d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
        fill="currentColor"
      />
      <path
        d="M24 3H23V1C23 0.734784 22.8946 0.48043 22.7071 0.292893C22.5196 0.105357 22.2652 0 22 0C21.7348 0 21.4804 0.105357 21.2929 0.292893C21.1054 0.48043 21 0.734784 21 1V3H3V1C3 0.734784 2.89464 0.48043 2.70711 0.292893C2.51957 0.105357 2.26522 0 2 0C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1V3H0V5H1V7C1 7.26522 1.10536 7.51957 1.29289 7.70711C1.48043 7.89464 1.73478 8 2 8C2.26522 8 2.51957 7.89464 2.70711 7.70711C2.89464 7.51957 3 7.26522 3 7V5H21V7C21 7.26522 21.1054 7.51957 21.2929 7.70711C21.4804 7.89464 21.7348 8 22 8C22.2652 8 22.5196 7.89464 22.7071 7.70711C22.8946 7.51957 23 7.26522 23 7V5H24V3Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
