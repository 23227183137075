import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const Blockquote1Icon = (props: IconProps) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M5.5 4C4.30653 4 3.16193 4.47411 2.31802 5.31802C1.47411 6.16193 1 7.30653 1 8.5C1 9.69347 1.47411 10.8381 2.31802 11.682C3.16193 12.5259 4.30653 13 5.5 13C5.521 13 5.54 12.994 5.56 12.994C5.07517 15.2644 4.26053 17.4516 3.142 19.486C3.01658 19.6953 2.97202 19.9433 3.01671 20.1832C3.06141 20.423 3.19228 20.6383 3.38467 20.7884C3.57706 20.9385 3.8177 21.0131 4.06125 20.9981C4.30481 20.9831 4.53448 20.8796 4.707 20.707C4.923 20.491 10 15.341 10 9C9.99369 8.94343 9.98231 8.88754 9.966 8.833C9.975 8.721 10 8.614 10 8.5C9.99868 7.30693 9.52415 6.16311 8.68052 5.31948C7.83689 4.47585 6.69307 4.00132 5.5 4Z"
        fill="currentColor"
      />
      <path
        d="M23 8.5C23 7.60999 22.7361 6.73996 22.2416 5.99994C21.7471 5.25991 21.0443 4.68314 20.2221 4.34254C19.3998 4.00195 18.495 3.91283 17.6221 4.08647C16.7492 4.2601 15.9474 4.68868 15.318 5.31802C14.6887 5.94736 14.2601 6.74918 14.0865 7.6221C13.9128 8.49501 14.0019 9.39981 14.3425 10.2221C14.6831 11.0443 15.2599 11.7471 15.9999 12.2416C16.74 12.7361 17.61 13 18.5 13C18.521 13 18.54 12.994 18.56 12.994C18.0752 15.2644 17.2605 17.4516 16.142 19.486C16.0166 19.6953 15.972 19.9433 16.0167 20.1832C16.0614 20.4231 16.1923 20.6383 16.3847 20.7884C16.5771 20.9385 16.8177 21.0131 17.0613 20.9981C17.3048 20.9831 17.5345 20.8796 17.707 20.707C17.923 20.491 23 15.341 23 9C22.9937 8.94343 22.9823 8.88754 22.966 8.833C22.975 8.721 23 8.614 23 8.5Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
