import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const MoonIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4 13.6992C20.6 13.8992 19.8 13.9992 19 13.9992C14 13.9992 10 9.99922 10 4.99922C10 4.19922 10.1 3.39922 10.3 2.59922C10.4 2.29922 10.3 1.89922 10 1.59922C9.7 1.29922 9.4 1.19922 9 1.29922C4.3 2.69922 1 7.09922 1 11.9992C1 18.0992 5.9 22.9992 12 22.9992C16.9 22.9992 21.3 19.6992 22.6 14.8992C22.7 14.5992 22.6 14.1992 22.3 13.8992C22.1 13.6992 21.7 13.5992 21.4 13.6992Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
