import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const MedicineBallIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth=".658"
      d="M18.877 3.397A10.922 10.922 0 0 0 11.939.923a10.92 10.92 0 0 0-6.935 2.47A11.078 11.078 0 0 0 .924 12a11.076 11.076 0 0 0 4.898 9.213 10.923 10.923 0 0 0 6.116 1.864 10.922 10.922 0 0 0 7.104-2.613A11.076 11.076 0 0 0 22.954 12c0-3.473-1.59-6.572-4.077-8.603Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        stroke="#fff"
        strokeWidth=".658"
        d="M18.877 3.397A10.922 10.922 0 0 0 11.939.923a10.92 10.92 0 0 0-6.935 2.47A11.078 11.078 0 0 0 .924 12a11.076 11.076 0 0 0 4.898 9.213 10.923 10.923 0 0 0 6.116 1.864 10.922 10.922 0 0 0 7.104-2.613A11.076 11.076 0 0 0 22.954 12c0-3.473-1.59-6.572-4.077-8.603Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.877 3.397A10.922 10.922 0 0 0 11.939.923a10.92 10.92 0 0 0-6.935 2.47A11.078 11.078 0 0 0 .924 12a11.076 11.076 0 0 0 4.898 9.213 10.923 10.923 0 0 0 6.116 1.864 10.922 10.922 0 0 0 7.104-2.613A11.076 11.076 0 0 0 22.954 12c0-3.473-1.59-6.572-4.077-8.603Zm-6.73 7.296a1.33 1.33 0 0 1 1.122.638c.115.19.178.4.185.632.013.405-.126.706-.417.918-.29.212-.738.318-1.338.32-.514.003-.931-.055-1.25-.174.004-.268.01-.537.017-.806.168.054.361.098.577.133.216.036.403.054.56.051.219-.003.387-.043.503-.122a.391.391 0 0 0 .174-.35.394.394 0 0 0-.178-.338c-.118-.082-.295-.123-.533-.116a1.982 1.982 0 0 0-.589.117c-.14-.052-.279-.1-.417-.146.078-.647.162-1.298.25-1.95a6.779 6.779 0 0 1 2.253-.01c.016.276.03.551.043.827a8.942 8.942 0 0 0-1.398-.07l-.056.495c.149-.03.255-.044.317-.047.063-.002.121-.003.174-.002Zm-1.784 3.976a5.483 5.483 0 0 0 3.204 0l.017-.75a6.336 6.336 0 0 1-3.239 0l.018.75Zm5.083-2.7c0 4.22 1.144 7.642 3.452 7.642s4.179-3.421 4.179-7.642-1.871-7.643-4.18-7.643c-2.307 0-3.45 3.422-3.45 7.643Zm-6.892 0c0 4.22-1.143 7.642-3.452 7.642-2.308 0-4.179-3.421-4.179-7.642s1.871-7.643 4.18-7.643c2.308 0 3.45 3.422 3.45 7.643Z"
        clipRule="evenodd"
      />
    </g>
  </StyledIcon>
)
