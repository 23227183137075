import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const IntensityBitEasyIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      d="M10.1254 15.849L10.1254 15.8491C10.8323 17.6619 11.2931 18.7631 11.5838 19.3282L11.5839 19.3283C11.8275 19.8004 12.0935 20.0784 12.5243 20.2994L12.5246 20.2995C12.6641 20.3707 12.8532 20.4254 13.0397 20.4583C13.2261 20.4911 13.4224 20.5044 13.5779 20.4853C14.0063 20.4338 14.4076 20.2514 14.7256 19.9632L14.726 19.9629C14.9041 19.8001 15.0366 19.6454 15.1346 19.4691C15.2326 19.2927 15.2925 19.1011 15.3334 18.8691C15.3784 18.6136 15.3852 18.5117 15.3625 18.3368L15.3625 18.3362C15.2984 17.8569 15.1163 17.5011 14.7403 17.1063C14.1067 16.4394 12.5155 14.9976 10.0289 12.8357L10.0288 12.8356C9.65341 12.5102 9.27887 12.1838 8.90512 11.8565C8.88817 11.841 8.86846 11.8253 8.84854 11.8138C8.84073 11.8093 8.82075 11.7982 8.79484 11.794C8.78234 11.7919 8.75193 11.7886 8.71829 11.8044C8.67618 11.8242 8.65307 11.862 8.64672 11.8981C8.64383 11.9144 8.64498 11.9276 8.64529 11.931L8.64529 11.9311C8.64577 11.9365 8.64649 11.9408 8.64693 11.9432C8.64782 11.9481 8.64884 11.9521 8.6494 11.9543C8.65059 11.9589 8.65198 11.9634 8.65309 11.967C8.65544 11.9744 8.65865 11.984 8.66241 11.9948C8.67001 12.0168 8.68113 12.0479 8.69524 12.0867C8.72353 12.1645 8.76456 12.2754 8.81523 12.4112C8.91661 12.6828 9.05699 13.0552 9.21205 13.4643C9.52216 14.2824 9.89136 15.248 10.1254 15.849Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      d="M22.2766 20.6895C22.8547 21.0362 23.6225 20.8562 23.9223 20.2354C24.681 18.6641 25.0925 16.9434 25.1232 15.1906C25.1603 13.0661 24.6366 10.9693 23.6047 9.11097C22.5728 7.2526 21.0691 5.69816 19.2448 4.60385C17.4204 3.50954 15.3396 2.91392 13.2116 2.87684C11.0836 2.83976 8.98328 3.36253 7.12176 4.3926C5.26025 5.42268 3.70312 6.92379 2.6069 8.74507C1.51067 10.5664 0.913994 12.6436 0.876847 14.7681C0.846199 16.5209 1.19732 18.2549 1.90065 19.8516C2.17854 20.4825 2.93958 20.6892 3.52939 20.3628C4.12505 20.0332 4.324 19.2933 4.0694 18.6812C3.56102 17.459 3.3083 16.1418 3.33157 14.8109C3.36119 13.1167 3.83701 11.4602 4.71122 10.0077C5.58544 8.55532 6.82724 7.35817 8.31186 6.53665C9.79647 5.71513 11.4716 5.2982 13.1688 5.32777C14.866 5.35734 16.5255 5.83238 17.9804 6.70512C19.4354 7.57786 20.6346 8.81754 21.4575 10.2995C22.2804 11.7815 22.6981 13.4537 22.6684 15.1478C22.6452 16.4787 22.3465 17.7864 21.7957 18.9901C21.5199 19.5929 21.6929 20.3393 22.2766 20.6895Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      d="M22.2766 20.6895C22.8547 21.0362 23.6225 20.8562 23.9223 20.2354C24.681 18.6641 25.0925 16.9434 25.1232 15.1906C25.1603 13.0661 24.6366 10.9693 23.6047 9.11097C22.5728 7.2526 21.0691 5.69816 19.2448 4.60385C17.4204 3.50954 15.3396 2.91392 13.2116 2.87684C11.0836 2.83976 8.98328 3.36253 7.12176 4.3926C5.26025 5.42268 3.70312 6.92379 2.6069 8.74507C1.51067 10.5664 0.913994 12.6436 0.876847 14.7681C0.846199 16.5209 1.19732 18.2549 1.90065 19.8516C2.17854 20.4825 2.93958 20.6892 3.52939 20.3628C4.12505 20.0332 4.324 19.2933 4.0694 18.6812C3.56102 17.459 3.3083 16.1418 3.33157 14.8109C3.36119 13.1167 3.83701 11.4602 4.71122 10.0077C5.58544 8.55532 6.82724 7.35817 8.31186 6.53665C9.79647 5.71513 11.4716 5.2982 13.1688 5.32777C14.866 5.35734 16.5255 5.83238 17.9804 6.70512C19.4354 7.57786 20.6346 8.81754 21.4575 10.2995C22.2804 11.7815 22.6981 13.4537 22.6684 15.1478C22.6452 16.4787 22.3465 17.7864 21.7957 18.9901C21.5199 19.5929 21.6929 20.3393 22.2766 20.6895Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
  </StyledIcon>
)
