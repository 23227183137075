import { IconProps } from 'fitify-ui/src/Icon/Icon.Types'
import dynamic from 'next/dynamic'

import { IconTypes } from './Icon.Types'

const AiIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/AiIcon').then((mod) => mod.AiIcon)
)
const AndroidIcon = dynamic(() =>
  import('fitify-ui/src/Icon/logo/AndroidIcon').then((mod) => mod.AndroidIcon)
)
const Apple2Icon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/Apple2Icon').then((mod) => mod.Apple2Icon)
)
const AppleIcon = dynamic(() =>
  import('fitify-ui/src/Icon/logo/AppleIcon').then((mod) => mod.AppleIcon)
)
const AppleRoundedIcon = dynamic(() =>
  import('fitify-ui/src/Icon/logo/AppleRoundedIcon').then(
    (mod) => mod.AppleRoundedIcon
  )
)
const ArcheryTargetIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/ArcheryTargetIcon').then(
    (mod) => mod.ArcheryTargetIcon
  )
)
const ArrowDownIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ArrowDownIcon').then(
    (mod) => mod.ArrowDownIcon
  )
)
const ArrowSIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ArrowSIcon').then((mod) => mod.ArrowSIcon)
)
const ArrowNIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ArrowNIcon').then((mod) => mod.ArrowNIcon)
)
const ArrowUpIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ArrowUpIcon').then(
    (mod) => mod.ArrowUpIcon
  )
)
const BalanceIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BalanceIcon').then((mod) => mod.BalanceIcon)
)
const BarbellIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BarbellIcon').then((mod) => mod.BarbellIcon)
)
const BedroomIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BedroomIcon').then((mod) => mod.BedroomIcon)
)
const BicepFillIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BicepIcon').then((mod) => mod.BicepIcon)
)
const BicepIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/BicepIcon').then((mod) => mod.BicepIcon)
)
const BodyBuilderIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BodyBuilderIcon').then(
    (mod) => mod.BodyBuilderIcon
  )
)
const BosuIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BosuIcon').then((mod) => mod.BosuIcon)
)
const BoxArrowTopRightIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BoxArrowTopRightIcon').then(
    (mod) => mod.BoxArrowTopRightIcon
  )
)
const BoxArrowBottomRightIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/BoxArrowBottomRightIcon').then(
    (mod) => mod.BoxArrowBottomRightIcon
  )
)
const BrokenHeartIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/BrokenHeartIcon').then(
    (mod) => mod.BrokenHeartIcon
  )
)
const Calendar2Icon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/Calendar2Icon').then(
    (mod) => mod.Calendar2Icon
  )
)
const CalendarRepeatIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/CalendarRepeatIcon').then(
    (mod) => mod.CalendarRepeatIcon
  )
)
const CCheckIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/CCheckIcon').then((mod) => mod.CCheckIcon)
)
const Copy2Icon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/Copy2Icon').then((mod) => mod.Copy2Icon)
)
const CRemoveIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/CRemoveIcon').then((mod) => mod.CRemoveIcon)
)
const CWarningIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/CWarningIcon').then((mod) => mod.CWarningIcon)
)
const DumbbellIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/DumbbellIcon').then((mod) => mod.DumbbellIcon)
)
const EnergyIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/EnergyIcon').then((mod) => mod.EnergyIcon)
)
const FlameIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/FlameIcon').then((mod) => mod.FlameIcon)
)
const FoamRollerIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/FoamRollerIcon').then(
    (mod) => mod.FoamRollerIcon
  )
)
const HeartbeatIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/HeartbeatIcon').then(
    (mod) => mod.HeartbeatIcon
  )
)
const HeartIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/HeartIcon').then((mod) => mod.HeartIcon)
)
const HeartOutlineIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/HeartIcon').then((mod) => mod.HeartIcon)
)
const IntensityBitEasyIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/IntensityBitEasyIcon').then(
    (mod) => mod.IntensityBitEasyIcon
  )
)
const IntensityBitHardIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/IntensityBitHardIcon').then(
    (mod) => mod.IntensityBitHardIcon
  )
)
const IntensityPerfectIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/IntensityPerfectIcon').then(
    (mod) => mod.IntensityPerfectIcon
  )
)
const IntensityTooEasyIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/IntensityTooEasyIcon').then(
    (mod) => mod.IntensityTooEasyIcon
  )
)
const IntensityTooHardIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/IntensityTooHardIcon').then(
    (mod) => mod.IntensityTooHardIcon
  )
)
const KettlebellIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/KettlebellIcon').then(
    (mod) => mod.KettlebellIcon
  )
)
const LatStationIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/LatStationIcon').then(
    (mod) => mod.LatStationIcon
  )
)
const LogoMessengerIcon = dynamic(() =>
  import('fitify-ui/src/Icon/logo/LogoMessengerIcon').then(
    (mod) => mod.LogoMessengerIcon
  )
)
const LungsIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/LungsIcon').then((mod) => mod.LungsIcon)
)
const MagnifierIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/MagnifierIcon').then(
    (mod) => mod.MagnifierIcon
  )
)
const MaleIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/MaleIcon').then((mod) => mod.MaleIcon)
)
const MedicineBallIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/MedicineBallIcon').then(
    (mod) => mod.MedicineBallIcon
  )
)
const MissingIcon = dynamic(() =>
  import('fitify-ui/src/Icon/MissingIcon').then((mod) => mod.MissingIcon)
)
const PullUpBarIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/PullUpBarIcon').then(
    (mod) => mod.PullUpBarIcon
  )
)
const ResistanceBandIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ResistanceBandIcon').then(
    (mod) => mod.ResistanceBandIcon
  )
)
const SandbagIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/SandbagIcon').then((mod) => mod.SandbagIcon)
)
const StandingManIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/StandingManIcon').then(
    (mod) => mod.StandingManIcon
  )
)
const StarIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/StarIcon').then((mod) => mod.StarIcon)
)
const StopwatchIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/StopwatchIcon').then(
    (mod) => mod.StopwatchIcon
  )
)
const StravaRoundedIcon = dynamic(() =>
  import('fitify-ui/src/Icon/logo/StravaRoundedIcon').then(
    (mod) => mod.StravaRoundedIcon
  )
)
const StretchingIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/StretchingIcon').then(
    (mod) => mod.StretchingIcon
  )
)
const SwissBallIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/SwissBallIcon').then(
    (mod) => mod.SwissBallIcon
  )
)
const ThumbDownIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ThumbDownIcon').then(
    (mod) => mod.ThumbDownIcon
  )
)
const ThumbUpIcon = dynamic(() =>
  import('fitify-ui/src/Icon/outline/ThumbUpIcon').then(
    (mod) => mod.ThumbUpIcon
  )
)
const TrophyIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/TrophyIcon').then((mod) => mod.TrophyIcon)
)
const TrxIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/TrxIcon').then((mod) => mod.TrxIcon)
)
const WarningSignIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/WarningSignIcon').then(
    (mod) => mod.WarningSignIcon
  )
)
const WeightScaleIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/WeightScaleIcon').then(
    (mod) => mod.WeightScaleIcon
  )
)
const YogaIcon = dynamic(() =>
  import('fitify-ui/src/Icon/fill/YogaIcon').then((mod) => mod.YogaIcon)
)

type Props = IconProps & {
  name?: IconTypes.Variants | null
}

export const Icon = ({ name, ...iconProps }: Props) => {
  switch (name) {
    case 'energy':
      return <EnergyIcon {...iconProps} />
    case 'flame':
      return <FlameIcon {...iconProps} />
    case 'heart-outline':
      return <HeartOutlineIcon {...iconProps} />
    case 'thumb-up':
      return <ThumbUpIcon {...iconProps} />
    case 'thumb-down':
      return <ThumbDownIcon {...iconProps} />
    case 'arrow-down':
      return <ArrowDownIcon {...iconProps} />
    case 'arrow-up':
      return <ArrowUpIcon {...iconProps} />
    case 'heart':
      return <HeartIcon {...iconProps} />
    case 'balance':
      return <BalanceIcon {...iconProps} />
    case 'standing-man':
      return <StandingManIcon {...iconProps} />
    case 'magnifier':
      return <MagnifierIcon {...iconProps} />
    case 'barbell':
      return <BarbellIcon {...iconProps} />
    case 'bosu':
      return <BosuIcon {...iconProps} />
    case 'dumbbell':
      return <DumbbellIcon {...iconProps} />
    case 'foamroller':
      return <FoamRollerIcon {...iconProps} />
    case 'kettlebell':
      return <KettlebellIcon {...iconProps} />
    case 'medicineball':
      return <MedicineBallIcon {...iconProps} />
    case 'pullupbar':
      return <PullUpBarIcon {...iconProps} />
    case 'resistanceband':
      return <ResistanceBandIcon {...iconProps} />
    case 'swissball':
      return <SwissBallIcon {...iconProps} />
    case 'trx':
      return <TrxIcon {...iconProps} />
    case 'c-remove':
      return <CRemoveIcon {...iconProps} />
    case 'c-warning':
      return <CWarningIcon {...iconProps} />
    case 'bicep-outline':
      return <BicepIcon {...iconProps} />
    case 'stretching':
      return <StretchingIcon {...iconProps} />
    case 'star':
      return <StarIcon {...iconProps} />
    case 'sandbag':
      return <SandbagIcon {...iconProps} />
    case 'lat-station':
      return <LatStationIcon {...iconProps} />
    case 'stopwatch':
      return <StopwatchIcon {...iconProps} />
    case 'intensity-too-easy':
      return <IntensityTooEasyIcon {...iconProps} />
    case 'intensity-bit-easy':
      return <IntensityBitEasyIcon {...iconProps} />
    case 'intensity-perfect':
      return <IntensityPerfectIcon {...iconProps} />
    case 'intensity-bit-hard':
      return <IntensityBitHardIcon {...iconProps} />
    case 'intensity-too-hard':
      return <IntensityTooHardIcon {...iconProps} />
    case 'box-arrow-top-right':
      return <BoxArrowTopRightIcon {...iconProps} />
    case 'box-arrow-bottom-right':
      return <BoxArrowBottomRightIcon {...iconProps} />
    case 'yoga':
      return <YogaIcon {...iconProps} />
    case 'apple-2':
      return <Apple2Icon {...iconProps} />
    case 'arrow-s':
      return <ArrowSIcon {...iconProps} />
    case 'arrow-n':
      return <ArrowNIcon {...iconProps} />
    case 'trophy':
      return <TrophyIcon {...iconProps} />
    case 'calendar-repeat':
      return <CalendarRepeatIcon {...iconProps} />
    case 'c-check':
      return <CCheckIcon {...iconProps} />
    case 'copy':
      return <Copy2Icon {...iconProps} />
    case 'ai':
      return <AiIcon {...iconProps} />
    case 'warning-sign':
      return <WarningSignIcon {...iconProps} />
    case 'broken-heart':
      return <BrokenHeartIcon {...iconProps} />
    case 'bicep':
      return <BicepFillIcon {...iconProps} />
    case 'heartbeat':
      return <HeartbeatIcon {...iconProps} />
    case 'apple':
      return <AppleIcon {...iconProps} />
    case 'android':
      return <AndroidIcon {...iconProps} />
    case 'archery-target':
      return <ArcheryTargetIcon {...iconProps} />
    case 'weight-scale':
      return <WeightScaleIcon {...iconProps} />
    case 'logo-messenger':
      return <LogoMessengerIcon {...iconProps} />
    case 'body-builder':
      return <BodyBuilderIcon {...iconProps} />
    case 'bedroom':
      return <BedroomIcon {...iconProps} />
    case 'lungs':
      return <LungsIcon {...iconProps} />
    case 'male':
      return <MaleIcon {...iconProps} />
    case 'strava-rounded':
      return <StravaRoundedIcon {...iconProps} />
    case 'apple-rounded':
      return <AppleRoundedIcon {...iconProps} />
    case 'calendar-2':
      return <Calendar2Icon {...iconProps} />

    default:
      return <MissingIcon {...iconProps} />
  }
}
