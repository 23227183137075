import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const PersonalTrainerIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
    fill="none"
  >
    <path
      d="M43.9997 29.77L33.9997 20.77C33.4506 20.2758 32.7384 20.0017 31.9997 20H15.9997C15.261 20.0017 14.5488 20.2758 13.9997 20.77L3.99969 29.77C3.49468 30.2242 3.15876 30.8364 3.04686 31.5063C2.93496 32.1762 3.05371 32.8643 3.38369 33.458L8.38369 42.458C8.77038 43.1534 9.41747 43.6667 10.1826 43.885C10.9478 44.1033 11.7683 44.0087 12.4637 43.622C13.1591 43.2353 13.6724 42.5882 13.8907 41.8231C14.109 41.0579 14.0144 40.2374 13.6277 39.542L9.78369 32.63L13.9997 28.836V46C13.9997 46.5304 14.2104 47.0391 14.5855 47.4142C14.9606 47.7893 15.4693 48 15.9997 48H31.9997C32.5301 48 33.0388 47.7893 33.4139 47.4142C33.789 47.0391 33.9997 46.5304 33.9997 46V28.836L38.2157 32.636L34.3777 39.548C34.1862 39.8923 34.0644 40.271 34.0193 40.6624C33.9742 41.0538 34.0066 41.4502 34.1147 41.8291C34.2228 42.2079 34.4044 42.5618 34.6493 42.8705C34.8941 43.1791 35.1974 43.4365 35.5417 43.628C35.886 43.8195 36.2647 43.9412 36.6561 43.9864C37.0475 44.0315 37.4439 43.9991 37.8228 43.891C38.2016 43.7829 38.5555 43.6013 38.8642 43.3564C39.1728 43.1116 39.4302 42.8083 39.6217 42.464L44.6217 33.464C44.9525 32.869 45.0711 32.1793 44.9581 31.508C44.8451 30.8367 44.5071 30.2238 43.9997 29.77Z"
      fill="#95EB00"
    />
    <path
      d="M24 16C28.4183 16 32 12.4183 32 8C32 3.58172 28.4183 0 24 0C19.5817 0 16 3.58172 16 8C16 12.4183 19.5817 16 24 16Z"
      fill="#95EB00"
    />
  </StyledIcon>
)
