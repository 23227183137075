import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const MissingAvatarIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 123 123"
    width="123"
    height="123"
    {...props}
  >
    <path fill="#b3b3b3" fillRule="evenodd" d="M0 0h123v123H0V0z" />
    <path
      fill="#ffffff"
      d="M48.64 77.72c.65-1.48 1.24-3.1 1.61-4.19a52.43 52.43 0 0 1-4.22-6l-4.27-6.83a12.55 12.55 0 0 1-2.43-6.21 4.94 4.94 0 0 1 .43-2.23 4.1 4.1 0 0 1 1.47-1.71 4.73 4.73 0 0 1 1-.52 107.7 107.7 0 0 1-.2-12.23 16.87 16.87 0 0 1 .55-2.8 16.39 16.39 0 0 1 7.22-9.2 22.79 22.79 0 0 1 6.05-2.69c1.37-.39-1.15-4.72.25-4.87 6.79-.7 17.77 5.5 22.51 10.62a16.63 16.63 0 0 1 4.19 10.51l-.27 11.1a3.06 3.06 0 0 1 2.25 2.32c.35 1.36 0 3.25-1.18 5.84a.37.37 0 0 1-.07.14l-4.87 8a41.6 41.6 0 0 1-6 8.24c.23.32.45.63.66.94 8.25 12.11 19.38 5.88 32.32 15.36l-.38.51v12.82H17.22V91.47h.24a1.14 1.14 0 0 1 .56-.61c8.38-4.86 27.7-6.51 30.62-13.14Z"
    />
  </StyledIcon>
)
