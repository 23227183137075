import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const PencilIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.908.021a3.144 3.144 0 0 0-1.423.61c-.151.116-3.616 3.55-7.7 7.63l-7.419 7.42-.693 4.149C.437 21.218.213 22.608 0 24c.009.009 1.884-.297 4.168-.678l4.15-.69 7.357-7.353c4.05-4.045 7.475-7.498 7.616-7.673.507-.628.704-1.191.706-2.018a2.907 2.907 0 0 0-.52-1.797c-.244-.368-2.98-3.096-3.317-3.309a3.529 3.529 0 0 0-.925-.4 4.75 4.75 0 0 0-1.327-.06Zm.898 2.196c.189.062.369.225 1.56 1.418 1.463 1.461 1.492 1.498 1.49 1.97 0 .368-.141.565-1.014 1.433l-.806.801-1.938-1.937-1.938-1.938.802-.805c.866-.873 1.065-1.014 1.433-1.014.108 0 .292.03.412.072ZM12.88 14.993l-5.641 5.642-2.306.383c-1.267.213-2.313.38-2.32.369-.008-.007.159-1.053.369-2.32l.383-2.305 5.64-5.64 5.64-5.642 1.938 1.937 1.938 1.938-5.641 5.638Z"
    />
  </StyledIcon>
)
