import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const StarIcon = (props: IconProps) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.265 1.534c.231-.712 1.239-.712 1.47 0l2.067 6.361c.104.319.4.534.735.534h6.688c.75 0 1.06.959.455 1.399l-5.411 3.931a.773.773 0 0 0-.28.865l2.066 6.36c.231.713-.584 1.305-1.19.865l-5.41-3.931a.773.773 0 0 0-.91 0l-5.41 3.93c-.606.441-1.421-.151-1.19-.864l2.067-6.36a.773.773 0 0 0-.281-.865l-5.41-3.931c-.607-.44-.295-1.399.454-1.399h6.688a.773.773 0 0 0 .735-.534l2.067-6.36Z"
    />
  </StyledIcon>
)
