import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const Multiple11Icon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#team)">
      <path d="M20 10C17.238 10 15 7.762 15 5 15 2.238 17.238 0 20 0 22.762 0 25 2.238 25 5 25 7.762 22.762 10 20 10ZM6.667 31.667V18.333C6.667 16.45 7.318 14.73 8.377 13.333H3.333C1.492 13.333 0 14.825 0 16.667V26.667H3.333V35C3.333 35.92 4.08 36.667 5 36.667H8.333C9.253 36.667 10 35.92 10 35V31.667H6.667ZM23.333 40H16.667C15.747 40 15 39.253 15 38.333V28.333H10V18.333C10 15.572 12.238 13.333 15 13.333H25C27.762 13.333 30 15.572 30 18.333V28.333H25V38.333C25 39.253 24.253 40 23.333 40ZM6.665 11.667C4.824 11.667 3.332 10.175 3.332 8.333 3.332 6.492 4.824 5 6.665 5 8.507 5 9.999 6.492 9.999 8.333 9.999 10.175 8.507 11.667 6.665 11.667ZM33.333 31.667V18.333C33.333 16.45 32.682 14.73 31.623 13.333H36.667C38.508 13.333 40 14.825 40 16.667V26.667H36.667V35C36.667 35.92 35.92 36.667 35 36.667H31.667C30.747 36.667 30 35.92 30 35V31.667H33.333ZM33.333 11.667C35.175 11.667 36.667 10.175 36.667 8.333 36.667 6.492 35.175 5 33.333 5 31.492 5 30 6.492 30 8.333 30 10.175 31.492 11.667 33.333 11.667Z" />
    </g>
    <defs>
      <clipPath id="team">
        <path fill="#fff" d="M0 0H40V40H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
