import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const TriangleSmUp = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.848 7.47C12.482 6.885 11.518 6.885 11.152 7.47L6.152 15.47C5.96 15.778 5.949 16.167 6.126 16.485C6.303 16.803 6.636 17 7 17H17C17.364 17 17.698 16.803 17.875 16.485C18.052 16.167 18.041 15.778 17.849 15.47L12.848 7.47Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
