import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const BicepIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.509.025c-.535.117-1.01.704-1.434 1.772-.392.988-.762 2.5-.842 3.445-.023.265-.023.93 0 1.2.099 1.17.448 2.32.83 2.728.217.232.6.416 1.062.51.32.066.634.09 1.092.085l.425-.005.267.07c.578.15.873.178 1.7.165.775-.013 1.085-.044 1.566-.155.663-.152 1.45-.496 1.791-.78a2.346 2.346 0 0 0 .818-1.791c0-.628-.211-1.18-.654-1.712-.213-.256-.608-.58-.861-.706a3.412 3.412 0 0 0-.653-.216c-.947-.195-2.198.028-3.232.576-.15.079-.24.134-.494.3-.037.024-.041.019-.229-.264-.202-.304-.35-.584-.408-.764-.05-.156-.12-.745-.12-1.004v-.112h.074c.161 0 .587-.19.833-.372.2-.148.371-.32.44-.444a.94.94 0 0 0 .037-.846c-.025-.056-.028-.082-.015-.125.066-.22.085-.602.04-.795a.759.759 0 0 0-.375-.5 2.853 2.853 0 0 0-.825-.261c-.191-.03-.698-.03-.833 0Zm.593.808c.182.018.364.059.525.119.12.045.133.074.11.234-.035.222-.045.244-.206.408l-.149.151.19.153c.106.084.196.164.202.178.026.07-.297.325-.539.426-.113.047-.137.051-.243.043a4.739 4.739 0 0 1-.664-.172c-.03-.01-.031.014-.023.713.008.717.02.904.086 1.34.062.417.242.793.691 1.449.28.408.285.418.42.77.068.177.128.322.134.322.006 0 .127-.138.269-.306.337-.399.495-.524.945-.751.67-.338 1.353-.51 2.024-.512.553-.002.926.104 1.253.352.581.443.882 1.053.821 1.663a1.48 1.48 0 0 1-.44.929c-.142.143-.163.156-.424.287a4.786 4.786 0 0 1-1.9.52c-1.037.072-1.676.018-2.362-.201a.551.551 0 0 0-.325-.032c-.095.01-.296.018-.449.018-.653 0-1.16-.121-1.37-.328-.205-.201-.47-1.052-.576-1.85a6.751 6.751 0 0 1 .057-2.173c.307-1.624.828-3.066 1.29-3.57.122-.133.173-.166.282-.18a1.24 1.24 0 0 1 .37 0Zm4.477 5.842c-.622.368-1.174.525-1.837.525-.313 0-.564-.034-.94-.127a4.128 4.128 0 0 0-.31-.07c-.002.003-.05.183-.105.4l-.1.397.06.016c.534.142.868.199 1.255.212a4.175 4.175 0 0 0 1.965-.406c.223-.106.675-.36.693-.39.01-.016-.393-.716-.41-.714-.004.001-.126.072-.271.157Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
