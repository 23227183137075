import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const PrivacyPolicyIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="37"
    viewBox="0 0 40 37"
    fill="none"
    {...props}
  >
    <path
      fill="#007AFF"
      d="M8.333 10.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM31.667 10.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM33.333 13c-4.791 0-7.265 3.442-8.75 5.54a17.246 17.246 0 0 1-3.063 3.563 3.741 3.741 0 0 1 1.64 3.979 15.959 15.959 0 0 0 3.507-3.354v11.939a1.667 1.667 0 0 0 1.666 1.666h10A1.667 1.667 0 0 0 40 34.667v-15A6.667 6.667 0 0 0 33.333 13ZM20.447 23.385a13.166 13.166 0 0 1-5.03-4.845C13.932 16.442 11.458 13 6.667 13A6.667 6.667 0 0 0 0 19.667v15a1.667 1.667 0 0 0 1.667 1.666h10a1.667 1.667 0 0 0 1.666-1.666V22.728a15.209 15.209 0 0 0 5.249 4.385 2.085 2.085 0 0 0 1.865-3.728Z"
    />
  </StyledIcon>
)
