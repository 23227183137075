import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const BedroomIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 13H2V4C2 3.73478 1.89464 3.48043 1.70711 3.29289C1.51957 3.10536 1.26522 3 1 3C0.734784 3 0.48043 3.10536 0.292893 3.29289C0.105357 3.48043 0 3.73478 0 4L0 21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946 0.734784 22 1 22C1.26522 22 1.51957 21.8946 1.70711 21.7071C1.89464 21.5196 2 21.2652 2 21V19H22V21C22 21.2652 22.1054 21.5196 22.2929 21.7071C22.4804 21.8946 22.7348 22 23 22C23.2652 22 23.5196 21.8946 23.7071 21.7071C23.8946 21.5196 24 21.2652 24 21V13Z"
        fill="currentColor"
      />
      <path
        d="M7 11C6.40666 11 5.82664 10.8241 5.33329 10.4944C4.83994 10.1648 4.45543 9.69623 4.22836 9.14805C4.0013 8.59987 3.94189 7.99667 4.05765 7.41473C4.1734 6.83279 4.45912 6.29824 4.87868 5.87868C5.29824 5.45912 5.83279 5.1734 6.41473 5.05765C6.99667 4.94189 7.59987 5.0013 8.14805 5.22836C8.69623 5.45543 9.16477 5.83994 9.49441 6.33329C9.82405 6.82664 10 7.40666 10 8C10 8.79565 9.68393 9.55871 9.12132 10.1213C8.55871 10.6839 7.79565 11 7 11Z"
        fill="currentColor"
      />
      <path
        d="M24 11H13C12.7348 11 12.4804 10.8946 12.2929 10.7071C12.1054 10.5196 12 10.2652 12 10V7C12 6.73478 12.1054 6.48043 12.2929 6.29289C12.4804 6.10536 12.7348 6 13 6H21C21.7956 6 22.5587 6.31607 23.1213 6.87868C23.6839 7.44129 24 8.20435 24 9V11Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
