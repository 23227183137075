import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const YogaIcon = (props: IconProps) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 6C13.657 6 15 4.657 15 3 15 1.343 13.657 0 12 0 10.343 0 9 1.343 9 3 9 4.657 10.343 6 12 6ZM5 17C4.735 17 4.48 17.105 4.293 17.293 4.105 17.48 4 17.735 4 18 4 18.265 4.105 18.52 4.293 18.707 4.48 18.895 4.735 19 5 19H19C19.265 19 19.52 18.895 19.707 18.707 19.895 18.52 20 18.265 20 18 20 17.735 19.895 17.48 19.707 17.293 19.52 17.105 19.265 17 19 17H5ZM24 21H0V23H24V21ZM9 10.174V16H15V10.174L16.849 11.759C17.026 11.911 17.25 11.996 17.483 12 17.716 12.004 17.943 11.927 18.125 11.781L21.406 9.156 20.156 7.594 17.522 9.7 14.932 7.48C14.569 7.17 14.107 6.999 13.63 7H10.37C9.893 7.001 9.432 7.171 9.07 7.482L6.478 9.7 3.844 7.594 2.594 9.156 5.875 11.781C6.057 11.927 6.284 12.004 6.517 12 6.75 11.996 6.974 11.911 7.151 11.759L9 10.174Z"
    />
  </StyledIcon>
)
