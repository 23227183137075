import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const TriangleSmDown = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 7H7C6.637 7 6.302 7.197 6.125 7.515C5.949 7.833 5.959 8.222 6.152 8.53L11.152 16.53C11.335 16.822 11.655 17 12 17C12.345 17 12.665 16.822 12.848 16.53L17.848 8.53C18.041 8.222 18.051 7.833 17.875 7.515C17.698 7.197 17.363 7 17 7Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
