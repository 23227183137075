import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ExchangeIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#exchange-icon)">
      <path d="M34.999 6.667H26.665V1.667L18.332 8.333 26.665 15V10H33.332V16.667H36.665V8.333C36.665 7.413 35.919 6.667 34.999 6.667ZM13.332 30H6.665V23.333H3.332V31.667C3.332 32.587 4.079 33.333 4.999 33.333H13.332V38.333L21.665 31.667 13.332 25V30ZM8.332 10C11.094 10 13.332 7.761 13.332 5 13.332 2.239 11.094 0 8.332 0 5.571 0 3.332 2.239 3.332 5 3.332 7.761 5.571 10 8.332 10ZM16.667 18.333H0V16.527C0 15.437.525 14.418 1.418 13.795 2.783 12.842 5.118 11.667 8.333 11.667 11.548 11.667 13.883 12.842 15.248 13.795 16.142 14.42 16.667 15.438 16.667 16.527V18.333ZM31.668 31.667C34.429 31.667 36.668 29.428 36.668 26.667 36.668 23.905 34.429 21.667 31.668 21.667 28.907 21.667 26.668 23.905 26.668 26.667 26.668 29.428 28.907 31.667 31.668 31.667ZM39.999 40H23.332V38.193C23.332 37.103 23.857 36.085 24.75 35.462 26.115 34.508 28.45 33.333 31.665 33.333 34.88 33.333 37.215 34.508 38.58 35.462 39.474 36.087 39.999 37.105 39.999 38.193V40Z" />
    </g>
    <defs>
      <clipPath id="exchange-icon">
        <path fill="#fff" d="M0 0H40V40H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
