import styled from 'styled-components'

import { colors } from '../theme/colors'

import { IconProps } from './Icon.Types'

export const StyledIcon = styled.svg<IconProps>`
  position: relative;
  color: ${(props) => (props.color ? colors[props.color] : 'initial')};
`
