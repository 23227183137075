import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const AChatTimeIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M0 18V9.5V1H24V8.5H22V3H2V16H6.999L7.01 18.023L7.02 20.046L9.852 18.023L10.7038 17.4145V19.8659L9.168 20.963L5.02 23.926L5.01 20.963L4.999 18H0Z"
      fill="currentColor"
      className="primary-color"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.578 9.00945L17.0958 9.07066C16.4257 9.15395 15.5295 9.47306 14.8935 9.85538C14.0625 10.3682 13.3622 11.0669 12.848 11.8965C12.4656 12.5447 12.201 13.2554 12.0663 13.9957C11.9779 14.5145 11.9779 15.4999 12.0663 16.0187C12.3898 17.9223 13.6065 19.5369 15.3858 20.426C15.7545 20.6107 16.5301 20.8565 16.9873 20.9338C17.5067 21.0221 18.4933 21.0221 19.0127 20.9338C20.9186 20.6107 22.5352 19.3954 23.4253 17.6183C23.6102 17.25 23.8563 16.4753 23.9337 16.0187C24.0221 15.4999 24.0221 14.5145 23.9337 13.9957C23.6102 12.0921 22.3935 10.4775 20.6142 9.58846C20.2445 9.40382 19.4668 9.15696 19.0338 9.08672C18.6541 9.02551 17.794 8.97935 17.578 9.00945ZM18.9504 11.1077C19.665 11.2835 20.3168 11.6538 20.8332 12.1774C21.5608 12.8866 21.9823 13.8516 22.0077 14.8667C22.0308 15.4163 21.9381 15.9647 21.7358 16.4763C21.5335 16.9879 21.226 17.4515 20.8332 17.837C20.3027 18.3679 19.714 18.703 18.9504 18.9098C18.6792 18.983 18.5164 18.9981 18 18.9981C17.4836 18.9981 17.3208 18.983 17.0496 18.9098C16.286 18.703 15.6973 18.3679 15.1668 17.837C14.6353 17.3072 14.2997 16.7192 14.0928 15.9565C13.9652 15.4839 13.9652 14.5306 14.0938 14.0569C14.3017 13.2893 14.6313 12.7123 15.1668 12.1774C15.6772 11.6582 16.3221 11.291 17.0295 11.1168C17.4745 11.0044 17.5248 10.9993 18.1005 11.0114C18.4742 11.0194 18.7163 11.0465 18.9504 11.1077ZM16.9953 14.0038V16.0107H21.0141V14.0038H19.0047V11.9968H16.9953V14.0038Z"
      fill="currentColor"
      className="secondary-color"
    />
  </StyledIcon>
)
