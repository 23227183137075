import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ChartBar33Icon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#chart-bar)">
      <path d="M24.134 21.667H17.467C16.547 21.667 15.801 22.413 15.801 23.333V38.333C15.801 39.253 16.547 40 17.467 40H24.134C25.054 40 25.801 39.253 25.801 38.333V23.333C25.801 22.413 25.054 21.667 24.134 21.667ZM9.134 28.333H2.467C1.547 28.333.801 29.08.801 30V38.333C.801 39.253 1.547 40 2.467 40H9.134C10.054 40 10.801 39.253 10.801 38.333V30C10.801 29.08 10.054 28.333 9.134 28.333ZM39.134 15H32.467C31.547 15 30.801 15.747 30.801 16.667V38.333C30.801 39.253 31.547 40 32.467 40H39.134C40.054 40 40.801 39.253 40.801 38.333V16.667C40.801 15.747 40.054 15 39.134 15ZM21.978 16.178 32.466 5.69V11.667H35.799V1.667C35.799.747 35.053 0 34.133 0H24.133V3.333H30.109L20.799 12.643 15.311 7.155C14.659 6.503 13.606 6.503 12.954 7.155L4.621 15.488 6.978 17.845 14.133 10.69 19.621 16.178C20.273 16.83 21.326 16.83 21.978 16.178Z" />
    </g>
    <defs>
      <clipPath id="chart-bar">
        <path fill="#fff" d="M0 0H40V40H0z" transform="translate(.8)" />
      </clipPath>
    </defs>
  </StyledIcon>
)
