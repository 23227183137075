import { CCheckIcon, CInfoIcon, WarningSignIcon } from 'fitify-ui/src/Icon/fill'
import { CrossIcon } from 'fitify-ui/src/Icon/outline'
import React from 'react'

import {
  IconWrapper,
  MessageActions,
  MessageContent,
  StyledContainer,
  TEXT_AND_ICON_SIZE,
} from '@/components/core/Toast/ToastMessage.Styled'
import { Text } from '@/components/core/Typography/Text'
import { Fitify } from '@/types/user'

type Props = Fitify.ToastMessage & {
  closeToast?: () => void
}

const ToastIcon = ({ type }: Pick<Props, 'type'>) => {
  switch (type) {
    case 'error':
      return <WarningSignIcon />
    case 'success':
      return <CCheckIcon />
    case 'info':
    default:
      return <CInfoIcon />
  }
}

export const ToastMessage: React.FC<Props> = ({
  action,
  closeToast,
  message,
  type,
}) => {
  return (
    <StyledContainer data-testid="toast-message" $type={type}>
      <MessageContent>
        <IconWrapper>
          <ToastIcon type={type} />
        </IconWrapper>
        <Text as="div" size={TEXT_AND_ICON_SIZE} variant="text">
          {message}
        </Text>
      </MessageContent>
      <MessageActions>
        {action && (
          <Text
            as="div"
            onClick={() => {
              action?.onClick()
              closeToast?.()
            }}
            size={TEXT_AND_ICON_SIZE}
            variant="headline-narrow"
          >
            {action?.text}
          </Text>
        )}
        <IconWrapper>
          <CrossIcon
            onClick={() => {
              closeToast?.()
            }}
          />
        </IconWrapper>
      </MessageActions>
    </StyledContainer>
  )
}
