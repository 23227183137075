import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const RefreshIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 12C21 16.963 16.963 21 12 21C7.037 21 3 16.963 3 12C3 7.037 7.037 3 12 3C13.989 3 15.873 3.65 17.43 4.833L13.826 8.226L22.993 9.209L22.562 0L18.907 3.442C16.957 1.862 14.545 1 12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23C18.065 23 23 18.065 23 12H21Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
