import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const GymClassIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#gym-class)">
      <path d="M18.535 10C17.546 10 16.58 9.707 15.757 9.157 14.935 8.608 14.294 7.827 13.916 6.913 13.537 6 13.438 4.994 13.631 4.025 13.824 3.055 14.3 2.164 15 1.464 15.699.765 16.59.289 17.56.096 18.53-.097 19.535.002 20.449.381 21.362.759 22.143 1.4 22.693 2.222 23.242 3.044 23.535 4.011 23.535 5 23.535 6.326 23.008 7.598 22.071 8.536 21.133 9.473 19.861 10 18.535 10ZM16.253 38.775 13.086 37.722 15.199 31.397V28.333H18.533V31.667C18.533 31.846 18.504 32.023 18.448 32.193L16.253 38.775ZM33.533 40H20.199L21.866 35H31.866L33.533 40Z" />
      <path d="M25.2005 23.3334H21.7839C21.8387 23.059 21.8666 22.7799 21.8672 22.5001V17.3084L26.0172 19.7667C26.3356 19.9555 26.7078 20.0329 27.075 19.9867C27.4423 19.9406 27.7838 19.7735 28.0455 19.5117L32.5572 15.0001L30.2005 12.6434L26.6039 16.2401L19.7355 12.2184C19.1166 11.861 18.4152 11.6709 17.7005 11.6667C17.1935 11.6703 16.6914 11.7663 16.2189 11.9501L7.93719 15.1167C7.62334 15.2371 7.35321 15.4495 7.16225 15.7261C6.97129 16.0027 6.86844 16.3306 6.86719 16.6667V23.3334H10.2005V17.8134L13.5339 16.5334V22.5001C13.5339 23.6051 13.9728 24.665 14.7542 25.4464C15.5356 26.2278 16.5955 26.6667 17.7005 26.6667H23.8989L25.2505 32.0701L28.4839 31.2634L26.8172 24.5967C26.7272 24.236 26.5192 23.9157 26.2263 23.6868C25.9334 23.4579 25.5723 23.3335 25.2005 23.3334Z" />
    </g>
    <defs>
      <clipPath id="gym-class">
        <path fill="#fff" d="M0 0H40V40H0z" transform="translate(.2)" />
      </clipPath>
    </defs>
  </StyledIcon>
)
