import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const StopwatchIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#stopWatch)">
      <path d="M5 1.667A4.167 4.167 0 1 0 5 10a4.167 4.167 0 0 0 0-8.334ZM7.083 6.25h-2.5v-2.5h.834v1.667h1.667v.833ZM6.667 0H3.334v.833h3.333V0Z" />
    </g>
    <defs>
      <clipPath id="stopWatch">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
