import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const IntensityTooHardIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
      d="M16.3906 18.2274L16.3906 18.2275C14.6633 19.1731 13.5897 19.7269 12.9968 19.9759L12.9967 19.9759C12.501 20.1834 12.1142 20.2258 11.6369 20.117L11.6366 20.1169C11.4823 20.0813 11.3002 20.0008 11.1343 19.905C10.9685 19.8093 10.8078 19.6919 10.6999 19.5761C10.4014 19.2577 10.2091 18.8556 10.1501 18.4256L10.1501 18.4251C10.1179 18.1831 10.1158 17.9771 10.1545 17.777C10.1932 17.5766 10.2714 17.3893 10.3905 17.1829C10.5217 16.9557 10.5827 16.8724 10.7139 16.7516L10.7143 16.7513C11.0755 16.4216 11.4478 16.2643 11.9959 16.2028C12.9209 16.098 15.0923 16.0154 18.4264 15.957L18.4266 15.957C18.9293 15.9488 19.432 15.9394 19.9346 15.9289C19.9579 15.9278 19.9832 15.9285 20.006 15.9325C20.0149 15.9341 20.0374 15.9384 20.06 15.9519C20.0709 15.9584 20.0963 15.9754 20.112 16.0091C20.1315 16.0513 20.1249 16.0952 20.1066 16.1269C20.0983 16.1413 20.089 16.1506 20.0865 16.153L20.0865 16.1531C20.0826 16.1569 20.0793 16.1598 20.0774 16.1613C20.0736 16.1645 20.0702 16.167 20.0684 16.1682C20.0645 16.171 20.0605 16.1736 20.0573 16.1756C20.0507 16.1799 20.042 16.1852 20.032 16.1912C20.0118 16.2033 19.983 16.2201 19.9468 16.241C19.8743 16.2829 19.7704 16.3422 19.6427 16.4145C19.3874 16.5592 19.0364 16.7565 18.65 16.9728C17.8774 17.4053 16.963 17.9138 16.3906 18.2274Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
      d="M22.2766 20.7197C22.8548 21.0672 23.6226 20.8866 23.9223 20.2649C24.681 18.691 25.0925 16.9674 25.1232 15.2116C25.1603 13.0836 24.6366 10.9832 23.6048 9.12168C22.5729 7.26016 21.0692 5.70304 19.2448 4.60685C17.4205 3.51065 15.3397 2.91399 13.2116 2.87685C11.0836 2.8397 8.98321 3.36338 7.12168 4.39524C5.26016 5.4271 3.70304 6.93078 2.60685 8.75516C1.51065 10.5795 0.913992 12.6603 0.876847 14.7884C0.8462 16.5442 1.19731 18.281 1.90062 19.8805C2.17844 20.5123 2.93951 20.7195 3.52947 20.3925C4.12512 20.0623 4.32393 19.3213 4.06943 18.7084C3.56103 17.4841 3.30829 16.1645 3.33157 14.8312C3.36119 13.1341 3.83703 11.4746 4.71127 10.0196C5.58551 8.56465 6.82734 7.36544 8.31193 6.54251C9.79654 5.71958 11.4716 5.30194 13.1688 5.33157C14.8659 5.36119 16.5254 5.83703 17.9804 6.71127C19.4353 7.58551 20.6346 8.82734 21.4575 10.3119C22.2804 11.7965 22.6981 13.4716 22.6684 15.1688C22.6452 16.502 22.3465 17.812 21.7957 19.0178C21.52 19.6214 21.6928 20.369 22.2766 20.7197Z"
    />
  </StyledIcon>
)
