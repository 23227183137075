import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const MicrophoneIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 16C15.3 16 18 13.3 18 10V6C18 2.7 15.3 0 12 0C8.7 0 6 2.7 6 6V10C6 13.3 8.7 16 12 16Z"
        fill="currentColor"
      />
      <path
        d="M22 10C22 9.4 21.6 9 21 9C20.4 9 20 9.4 20 10C20 14.4 16.4 18 12 18C7.6 18 4 14.4 4 10C4 9.4 3.6 9 3 9C2.4 9 2 9.4 2 10C2 15.2 6 19.4 11 19.9V22H7C6.4 22 6 22.4 6 23C6 23.6 6.4 24 7 24H17C17.6 24 18 23.6 18 23C18 22.4 17.6 22 17 22H13V19.9C18 19.4 22 15.2 22 10Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
