import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArrowRightIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.667 8.667h8.113l-3.726 3.726.946.94L13.334 8 8 2.667l-.94.94 3.72 3.726H2.667v1.334Z"
    />
  </StyledIcon>
)
