import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const SpaceshipIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#spaceship)">
      <path d="M39.301.707C39.136.54 38.938.411 38.72.326 38.501.242 38.267.205 38.033.217 14.653 1.437 6.016 22.263 5.933 22.473 5.811 22.776 5.781 23.108 5.846 23.428 5.911 23.748 6.069 24.042 6.3 24.273L15.728 33.702C15.96 33.934 16.256 34.092 16.577 34.156 16.899 34.221 17.233 34.189 17.536 34.065 17.745 33.98 38.46 25.247 39.783 1.977 39.797 1.743 39.761 1.509 39.678 1.29 39.595 1.072 39.466.873 39.301.707ZM25.691 19.023C25.225 19.49 24.631 19.807 23.985 19.936 23.338 20.065 22.668 19.999 22.059 19.746 21.45 19.494 20.929 19.067 20.563 18.519 20.196 17.971 20.001 17.326 20.001 16.667 20.001 16.007 20.196 15.363 20.563 14.815 20.929 14.267 21.45 13.839 22.059 13.587 22.668 13.335 23.338 13.269 23.985 13.398 24.631 13.526 25.225 13.844 25.691 14.31 26.316 14.935 26.667 15.783 26.667 16.667 26.667 17.551 26.316 18.398 25.691 19.023ZM1.855 31.407C2.295 30.964 2.817 30.613 3.393 30.372 3.969 30.132 4.586 30.008 5.21 30.007 5.834 30.006 6.452 30.128 7.028 30.366 7.605 30.605 8.129 30.954 8.57 31.395 9.011 31.836 9.361 32.36 9.599 32.937 9.837 33.513 9.959 34.131 9.958 34.755 9.957 35.379 9.833 35.996 9.593 36.572 9.352 37.148 9.001 37.67 8.558 38.11 6.707 39.962 0 40 0 40 0 40 0 33.257 1.855 31.407ZM17.264 3.902C14.914 3.493 12.501 3.656 10.229 4.376 7.956 5.097 5.89 6.354 4.205 8.042 3.314 8.941 2.539 9.949 1.899 11.042 1.711 11.36 1.635 11.732 1.681 12.098 1.728 12.465 1.895 12.806 2.157 13.067L5.472 16.383C8.552 11.495 12.558 7.255 17.264 3.902ZM36.099 22.738C36.508 25.088 36.345 27.501 35.624 29.773 34.904 32.046 33.647 34.112 31.959 35.797 31.059 36.688 30.051 37.463 28.959 38.103 28.64 38.291 28.269 38.367 27.902 38.321 27.536 38.274 27.195 38.107 26.934 37.845L23.617 34.53C28.506 31.45 32.746 27.444 36.099 22.738Z" />
    </g>
    <defs>
      <clipPath id="spaceship">
        <path fill="#fff" d="M0 0H40V40H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
