import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const EnergyIcon = (props: IconProps) => (
  <StyledIcon
    viewBox="0 0 24 24"
    height="24"
    width="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5858 9.439s1.114-6.581-3.448-9.439c-.0678 1.13092-.3734 2.23482-.8969 3.23958-.52352 1.00477-1.25308 1.8878-2.14106 2.59142-1.951 1.715-5.619 5.569-5.58 9.679-.01282 1.7571.46531 3.4829 1.38052 4.9829C4.81557 21.993 6.13148 23.2076 7.69984 24c.05604-.7932.26985-1.5673.62891-2.2768.35905-.7095.85613-1.3403 1.46209-1.8552.51366-.3949.94346-.8881 1.26436-1.4509.3209-.5628.5265-1.184.6046-1.8271 1.3428.7136 2.4773 1.7634 3.2927 3.0468.8154 1.2834 1.2838 2.7565 1.3593 4.2752v.02c1.4791-.679 2.7417-1.7537 3.6483-3.1053.9066-1.3516 1.4218-2.9276 1.4887-4.5537.324-3.863-1.792-9.112-3.668-10.828-.7082 1.58126-1.8078 2.95588-3.195 3.994Z"
      fill="currentColor"
    />
  </StyledIcon>
)
