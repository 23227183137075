import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const EAddIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6V11H1V13H11V23H13V13H23V11H13V1H11V6Z"
      fill="currentColor"
    />
  </StyledIcon>
)
