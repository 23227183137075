import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const CustomEffortIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 18V7h7.707v1.615h-5.414v3.016h4.345v1.538h-4.345v3.216H16V18H8Z"
    />
    <path
      stroke="currentColor"
      d="M20 1H4a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3Z"
    />
  </StyledIcon>
)
