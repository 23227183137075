import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const FCommentIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.707.342c-.971.07-1.816.385-2.495.93-.847.68-1.294 1.67-1.198 2.656C.158 5.402 1.4 6.595 3.127 6.919c.57.107 1.256.103 1.776-.01l.11-.025 1.187.483c1.099.447 1.187.48 1.187.452 0-.016-.054-.496-.12-1.065l-.12-1.035.093-.107c.628-.727.878-1.624.7-2.515C7.653 1.672 6.29.572 4.552.366a7.86 7.86 0 0 0-.846-.024Zm.838.691c1.321.183 2.372.958 2.687 1.98.079.256.094.361.093.654 0 .218-.005.282-.03.4-.101.472-.32.863-.707 1.269l-.136.143.075.648c.04.357.073.656.07.666-.002.01-.262-.089-.77-.296l-.767-.313-.193.047a2.928 2.928 0 0 1-.534.09c-.204.02-.663.008-.878-.021-1.317-.18-2.372-.957-2.687-1.98a1.76 1.76 0 0 1-.094-.653c0-.292.015-.397.094-.654.292-.946 1.229-1.696 2.418-1.933.169-.034.265-.047.514-.072.12-.012.7.005.845.025ZM2.333 3v.333h3.334v-.666H2.333V3Zm0 1.333v.334h2.334V4H2.333v.333Z"
      fill="currentColor"
    />
  </StyledIcon>
)
