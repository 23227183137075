import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const WorkoutPlanIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
    fill="none"
  >
    <g clipPath="url(#clip0_12202_87813)">
      <path
        d="M46 6H36V2C36 1.46957 35.7893 0.960859 35.4142 0.585786C35.0391 0.210714 34.5304 0 34 0C33.4696 0 32.9609 0.210714 32.5858 0.585786C32.2107 0.960859 32 1.46957 32 2V6H16V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0C13.4696 0 12.9609 0.210714 12.5858 0.585786C12.2107 0.960859 12 1.46957 12 2V6H2C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 0 7.46957 0 8L0 44C0 44.5304 0.210714 45.0391 0.585786 45.4142C0.960859 45.7893 1.46957 46 2 46H16V42H4V14H44V24H48V8C48 7.46957 47.7893 6.96086 47.4142 6.58579C47.0391 6.21071 46.5304 6 46 6Z"
        fill="currentColor"
      />
      <path
        d="M46 36H44V30C44 29.4696 43.7893 28.9609 43.4142 28.5858C43.0391 28.2107 42.5304 28 42 28H40C39.4696 28 38.9609 28.2107 38.5858 28.5858C38.2107 28.9609 38 29.4696 38 30V36H28V30C28 29.4696 27.7893 28.9609 27.4142 28.5858C27.0391 28.2107 26.5304 28 26 28H24C23.4696 28 22.9609 28.2107 22.5858 28.5858C22.2107 28.9609 22 29.4696 22 30V36H20C19.4696 36 18.9609 36.2107 18.5858 36.5858C18.2107 36.9609 18 37.4696 18 38C18 38.5304 18.2107 39.0391 18.5858 39.4142C18.9609 39.7893 19.4696 40 20 40H22V46C22 46.5304 22.2107 47.0391 22.5858 47.4142C22.9609 47.7893 23.4696 48 24 48H26C26.5304 48 27.0391 47.7893 27.4142 47.4142C27.7893 47.0391 28 46.5304 28 46V40H38V46C38 46.5304 38.2107 47.0391 38.5858 47.4142C38.9609 47.7893 39.4696 48 40 48H42C42.5304 48 43.0391 47.7893 43.4142 47.4142C43.7893 47.0391 44 46.5304 44 46V40H46C46.5304 40 47.0391 39.7893 47.4142 39.4142C47.7893 39.0391 48 38.5304 48 38C48 37.4696 47.7893 36.9609 47.4142 36.5858C47.0391 36.2107 46.5304 36 46 36Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12202_87813">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </StyledIcon>
)
