import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const LotusFlowerIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#lotus-icon)">
      <path d="M7.1 16.189C6.127 15.46 5.311 14.541 4.703 13.488 4.095 12.435 3.706 11.269 3.561 10.062 4.4 10.163 5.22 10.384 5.996 10.717 5.988 10.018 6.028 9.319 6.117 8.625 4.955 8.214 3.732 8.003 2.5 8 2.235 8 1.98 8.105 1.793 8.293 1.605 8.48 1.5 8.735 1.5 9 1.504 11.287 2.22 13.516 3.548 15.377 4.877 17.239 6.752 18.641 8.913 19.388 8.179 18.401 7.57 17.326 7.1 16.189ZM22.502 8C21.269 8.003 20.046 8.214 18.884 8.625 18.973 9.319 19.013 10.018 19.005 10.717 19.781 10.384 20.601 10.163 21.44 10.062 21.295 11.269 20.906 12.435 20.298 13.488 19.69 14.541 18.875 15.46 17.902 16.189 17.433 17.327 16.824 18.401 16.09 19.389 18.251 18.641 20.126 17.239 21.454 15.378 22.783 13.516 23.498 11.287 23.502 9 23.502 8.735 23.397 8.48 23.209 8.293 23.021 8.105 22.767 8 22.502 8ZM12.503 20C12.238 20 11.984 19.895 11.796 19.707 9.358 17.263 7.988 13.952 7.988 10.5 7.988 7.048 9.358 3.737 11.796 1.293 11.984 1.106 12.238 1 12.503 1 12.768 1 13.023 1.106 13.21 1.293 15.648 3.737 17.018 7.048 17.018 10.5 17.018 13.952 15.648 17.263 13.21 19.707 13.023 19.895 12.768 20 12.503 20ZM2.267 16.988C1.896 17.27 1.55 17.583 1.231 17.923 1.06 18.108.965 18.351.965 18.603.965 18.855 1.06 19.097 1.231 19.282 2.023 20.135 2.982 20.816 4.049 21.28 5.116 21.745 6.267 21.984 7.431 21.982 8.189 21.982 8.942 21.881 9.673 21.682 6.736 21.021 4.118 19.363 2.267 16.988ZM22.734 16.988C23.105 17.27 23.452 17.583 23.77 17.923 23.941 18.108 24.036 18.351 24.036 18.603 24.036 18.855 23.941 19.097 23.77 19.282 22.979 20.135 22.019 20.816 20.952 21.28 19.886 21.745 18.734 21.984 17.57 21.982 16.813 21.982 16.059 21.881 15.328 21.682 18.266 21.021 20.883 19.363 22.734 16.988Z" />
    </g>
    <defs>
      <clipPath id="lotus-icon">
        <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
      </clipPath>
    </defs>
  </StyledIcon>
)
