import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const WaterIntakeIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M21 0H3a1 1 0 0 0-1 1.091l1.834 20.18A2.987 2.987 0 0 0 6.827 24h10.346a2.987 2.987 0 0 0 2.99-2.729L22 1.091A.999.999 0 0 0 21 0Zm-1.913 11H4.913L4.1 2h15.81l-.823 9Z"
        fill="#2979FF"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
