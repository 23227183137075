import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const SleepIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)" fill="#2979FF">
      <path d="M3 24a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM19 6c-.6-3.4-3.5-6-7-6-2.3 0-4.4 1.1-5.7 3H6C2.7 3 0 5.7 0 9c0 2.7 1.8 5 4.4 5.8 1 3.1 3.9 5.2 7.1 5.2 2.8 0 5.4-1.6 6.7-4.1.3 0 .6.1.8.1 2.8 0 5-2.2 5-5s-2.2-5-5-5Zm-5 7h-4c-.4 0-.7-.2-.9-.5-.2-.3-.2-.7 0-1L11.2 8H10c-.6 0-1-.4-1-1s.4-1 1-1h3c.4 0 .7.2.9.5.2.3.2.7 0 1L11.8 11H14c.6 0 1 .4 1 1s-.4 1-1 1Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
