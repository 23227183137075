import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const CInfoIcon = (props: IconProps) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM14.658 18.284C13.997 18.544 11.706 19.638 10.386 18.475C9.992 18.129 9.796 17.69 9.796 17.157C9.796 16.159 10.124 15.289 10.715 13.2C10.819 12.805 10.946 12.293 10.946 11.887C10.946 11.186 10.68 11 9.959 11C9.607 11 9.217 11.125 8.864 11.257L9.059 10.458C9.846 10.138 10.834 9.748 11.68 9.748C12.949 9.748 13.883 10.381 13.883 11.585C13.883 11.932 13.823 12.54 13.697 12.96L12.967 15.542C12.816 16.064 12.543 17.215 12.966 17.556C13.382 17.893 14.367 17.714 14.853 17.485L14.658 18.284ZM13.452 8C12.624 8 11.952 7.328 11.952 6.5C11.952 5.672 12.624 5 13.452 5C14.28 5 14.952 5.672 14.952 6.5C14.952 7.328 14.28 8 13.452 8Z"
      fill="currentColor"
    />
  </StyledIcon>
)
