import { useCallback, useEffect, useRef } from 'react'
import FocusLock from 'react-focus-lock'

import useDelayUnmount from '../hooks/useDelayUnmount'
import useDisableBodyScroll from '../hooks/useDisableBodyScroll'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { CrossIcon } from '../Icon/outline'
import { Overlay } from '../Overlay/Overlay'

import {
  StyledModalClose,
  StyledModalContainer,
  StyledModalHeader,
  StyledModalLogo,
} from './Modal.Styled'
import {
  CloseButtonTypes,
  ModalConfigProps,
  ModalSizeTypes,
  ModalTypes,
} from './Modal.Types'
import ModalPortal from './ModalPortal'

// Default config
const initialConfig = {
  modalSize: ModalSizeTypes.DEFAULT,
  borderRadius: 24,
  showHeader: true,
  showOverlay: true,
  showLogo: false,
  showCloseButton: true,
  closeVariant: CloseButtonTypes.BLACK,
  animationDuration: 400,
  dataTestId: 'modal',
  overlayIndex: 999,
} satisfies ModalConfigProps

const Modal = ({
  options,
  children,
  show,
  handleClose,
  config,
  autofocus = true,
}: ModalTypes) => {
  const animationDuration =
    config?.animationDuration ?? initialConfig.animationDuration
  const showHeader = config?.showHeader ?? initialConfig.showHeader
  const showOverlay = config?.showOverlay ?? initialConfig.showOverlay
  const closeVariant = config?.closeVariant ?? initialConfig.closeVariant
  const modalSize = config?.modalSize ?? initialConfig.modalSize
  const borderRadius = config?.borderRadius ?? initialConfig.borderRadius
  const showLogo = config?.showLogo ?? initialConfig.showLogo
  const showCloseButton =
    config?.showCloseButton ?? initialConfig.showCloseButton
  const overlayIndex = config?.overlayIndex ?? initialConfig.overlayIndex

  const shouldRenderChild = useDelayUnmount(show, animationDuration)
  const modalRef = useRef<HTMLDivElement>(null)

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleClose()
    },
    [handleClose]
  )

  // hook co exit modal if clicked outside of container
  useOnClickOutside(modalRef, handleClose)

  // hook to disable body scroll when modal is shown
  useDisableBodyScroll(show)

  useEffect(() => {
    if (show) {
      // attach the event listener if the modal is shown
      document.addEventListener('keydown', handleKeyPress)
      // remove the event listener
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return shouldRenderChild ? (
    <ModalPortal wrapperId="modal-portal">
      <FocusLock autoFocus={autofocus}>
        <Overlay
          showOverlay={showOverlay}
          overlayIndex={overlayIndex}
          show={show}
          duration={animationDuration}
          data-testid={config?.dataTestId}
        >
          <StyledModalContainer
            ref={modalRef}
            size={modalSize}
            borderRadius={borderRadius}
          >
            {showCloseButton ? (
              options?.closeButton ? (
                options.closeButton
              ) : (
                <StyledModalClose
                  onClick={handleClose}
                  variant={closeVariant}
                  data-testid={'modal-close'}
                >
                  <CrossIcon color="Black" />
                </StyledModalClose>
              )
            ) : null}

            {options?.logo ? (
              <StyledModalLogo showLogo={showLogo}>
                {options.logo}
              </StyledModalLogo>
            ) : null}

            {showHeader && options?.title ? (
              <StyledModalHeader>{options.title}</StyledModalHeader>
            ) : null}

            {children}
          </StyledModalContainer>
        </Overlay>
      </FocusLock>
    </ModalPortal>
  ) : null
}

export default Modal
