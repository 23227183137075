import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArcheryTargetIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 24C4.486 24 0 19.514 0 14C0 8.486 4.486 4 10 4C10.375 4 10.746 4.021 11.109 4.061L12.103 4.17L11.884 6.158L10.89 6.049C10.598 6.017 10.301 6 10 6C5.589 6 2 9.589 2 14C2 18.411 5.589 22 10 22C14.411 22 18 18.411 18 14C18 13.699 17.983 13.401 17.951 13.109L17.842 12.115L19.83 11.896L19.939 12.89C19.979 13.255 20 13.625 20 14C20 19.514 15.514 24 10 24Z"
        fill="currentColor"
      />
      <path
        d="M10 20C6.691 20 4 17.309 4 14C4 10.691 6.691 8 10 8C13.309 8 16 10.691 16 14C16 17.309 13.309 20 10 20ZM10 10C7.794 10 6 11.794 6 14C6 16.206 7.794 18 10 18C12.206 18 14 16.206 14 14C14 11.794 12.206 10 10 10Z"
        fill="currentColor"
      />
      <path
        d="M19.27 10.1449L23.851 5.56293L20.851 4.56293L22.707 2.70693L21.293 1.29293L19.437 3.14893L18.437 0.148926L13.856 4.72993L14.856 7.73093L10.512 12.0749C10.347 12.0309 10.178 11.9999 10 11.9999C8.895 11.9999 8 12.8949 8 13.9999C8 15.1049 8.895 15.9999 10 15.9999C11.105 15.9999 12 15.1049 12 13.9999C12 13.8219 11.969 13.6529 11.926 13.4879L16.27 9.14393L19.27 10.1449Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
