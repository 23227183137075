import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const FitHorizontalIcon = (props: IconProps) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1 19.515c.552 0 1-.525 1-1.175V5.175C2 4.525 1.552 4 1 4s-1 .526-1 1.175V18.34c0 .65.448 1.175 1 1.175ZM23 20c.552 0 1-.543 1-1.212V5.212C24 4.543 23.552 4 23 4s-1 .543-1 1.212v13.576c0 .669.448 1.212 1 1.212ZM6 10.788h12v1.94H6v-1.94Z"
    />
    <path
      fill="currentColor"
      d="M15.13 8.294a.95.95 0 0 1 .309-.218.91.91 0 0 1 1.037.218L20 12l-3.524 3.706a.929.929 0 0 1-.673.293.93.93 0 0 1-.673-.293 1.027 1.027 0 0 1-.279-.707c0-.266.1-.52.279-.708L17.311 12 15.13 9.709a1.004 1.004 0 0 1-.207-.325 1.045 1.045 0 0 1 .207-1.09Zm-6.26 7.412a.95.95 0 0 1-.309.218.91.91 0 0 1-1.037-.218L4 12l3.524-3.706A.93.93 0 0 1 8.197 8a.93.93 0 0 1 .673.293c.179.187.279.442.279.707 0 .266-.1.52-.279.708L6.689 12l2.181 2.291a1.044 1.044 0 0 1 0 1.415Z"
    />
  </StyledIcon>
)
