import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const FlameIcon = (props: IconProps) => (
  <StyledIcon
    viewBox="0 0 24 24"
    height="24"
    width="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.778.312a1.079 1.079 0 0 0-.355-.23 1.136 1.136 0 0 0-.846 0 1.08 1.08 0 0 0-.355.23C10.89.625 2 9.078 2 14.608c0 2.491 1.054 4.88 2.929 6.641C6.804 23.011 9.348 24 12 24s5.196-.99 7.071-2.75C20.946 19.487 22 17.1 22 14.607 22 9.078 13.111.625 12.778.313ZM12 18.782a4.664 4.664 0 0 1-1.706-.309 4.443 4.443 0 0 1-1.447-.904 4.134 4.134 0 0 1-.962-1.358 3.923 3.923 0 0 1-.33-1.602c.112-1.67 2.223-4.592 3.556-6.262.104-.13.238-.234.392-.307a1.17 1.17 0 0 1 .994 0c.154.073.288.178.392.307 1.333 1.566 3.444 4.592 3.555 6.157a3.99 3.99 0 0 1-.318 1.623 4.2 4.2 0 0 1-.957 1.384 4.511 4.511 0 0 1-1.45.933 4.741 4.741 0 0 1-1.719.338Z"
    />
  </StyledIcon>
)
