import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const CWarningIcon = (props: IconProps) => (
  <StyledIcon
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M4 0C1.794 0 0 1.794 0 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Zm.548 1.667-.215 3h-.666l-.203-3h1.084Zm-.548 5a.666.666 0 1 1 0-1.333.666.666 0 0 1 0 1.333Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h8v8H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
