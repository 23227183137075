import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const Calendar2Icon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 3V1C19 0.734784 18.8946 0.48043 18.7071 0.292893C18.5196 0.105357 18.2652 0 18 0C17.7348 0 17.4804 0.105357 17.2929 0.292893C17.1054 0.48043 17 0.734784 17 1V3H19Z"
        fill="currentColor"
      />
      <path
        d="M7 3V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V3H7Z"
        fill="currentColor"
      />
      <path
        d="M21 23H3C2.20435 23 1.44129 22.6839 0.87868 22.1213C0.31607 21.5587 0 20.7956 0 20L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H21C21.7956 4 22.5587 4.31607 23.1213 4.87868C23.6839 5.44129 24 6.20435 24 7V20C24 20.7956 23.6839 21.5587 23.1213 22.1213C22.5587 22.6839 21.7956 23 21 23ZM22 9H2V20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20V9Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
