// Text
export { TextStyles } from './src/Text/Text.Styled'
export { default as Text } from './src/Text'

// Icon
export { StyledIcon } from './src/Icon/Icon.Styled'

export type { ThemeColors } from './src/theme/colors'
export * from './src/theme/colors'

export { default as useQuery } from './src/hooks/useQuery'
export { default as useDisableBodyScroll } from './src/hooks/useDisableBodyScroll'
export { default as useOnClickOutside } from './src/hooks/useOnClickOutside'
export { default as useDelayUnmount } from './src/hooks/useDelayUnmount'
export { default as useWindowSize } from './src/hooks/useWindowSize'
export { default as useImageSlider } from './src/hooks/useImageSlider'
export * from './src/hooks/useDeviceSize'
export * from './src/hooks/useSmartlook'
export * from './src/hooks/useRemoteConfig'

export { default as Modal } from './src/Modal/Modal'
export * from './src/Modal/Modal.Types'
