import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const BtnPlayIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0ZM9 17V7l8 5-8 5Z"
    />
  </StyledIcon>
)
