import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const LeftArrowIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.28 6.3L4.57996 12L10.29 17.71L16 23.42L16.71 22.71L17.42 22L12.42 17L7.41996 12L12.42 7L17.42 2L16.72 1.3C16.336 0.914998 16.012 0.599998 16 0.599998C15.989 0.599998 13.415 3.165 10.28 6.3Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
