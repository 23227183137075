import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const AChatIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.5V18H4.999L5.01 20.963L5.02 23.926L9.168 20.963L13.316 18H24V1H0V9.5ZM22 9.5V16H12.684L9.852 18.023L7.02 20.046L7.01 18.023L6.999 16H2V3H22V9.5Z"
      fill="currentColor"
    />
  </StyledIcon>
)
