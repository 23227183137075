import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArrowNIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 0 9 9"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.00033 0L6.33366 3.33333H4.33366V7.66667C4.33366 7.851 4.18433 8 4.00033 8C3.81633 8 3.66699 7.851 3.66699 7.66667V3.33333H1.66699L4.00033 0Z"
    />
  </StyledIcon>
)
