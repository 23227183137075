import { colors } from '../../theme/colors'
import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

type LargeCheckboxIconProps = IconProps & {
  checkColor?: string
  backgroundColor?: string
}

export const LargeCheckboxIcon = ({
  checkColor = colors.White,
  backgroundColor = colors.Black,
  ...rest
}: LargeCheckboxIconProps) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      {...rest}
    >
      <circle cx="44" cy="44" r="44" fill={backgroundColor} />
      <path
        fill={checkColor}
        d="M37.996 60.668 24.663 47.335l5-5 8.333 8.333 20-20 5 5-25 25z"
      />
    </StyledIcon>
  )
}
