import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ShareIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 23H2C1.73478 23 1.48043 22.8946 1.29289 22.7071C1.10536 22.5196 1 22.2652 1 22V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H8V7H3V21H17V14H19V22C19 22.2652 18.8946 22.5196 18.7071 22.7071C18.5196 22.8946 18.2652 23 18 23Z"
        fill="currentColor"
      />
      <path
        d="M24 5L18 0V4.051C15.2689 4.3026 12.73 5.56448 10.8804 7.58962C9.03079 9.61476 8.00363 12.2573 8 15H10C10.003 12.7874 10.8201 10.6532 12.2954 9.00432C13.7708 7.35541 15.8013 6.30703 18 6.059V10L24 5Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
