import { Timestamp } from 'firebase/firestore'

import { ConversationContextMessage } from '../ai-coach'
import { FirestoreDocumentId, FirestoreTimestamp, UnitSystem } from '../common'
import { FitifyApp, FitifyPlatform } from '../fitify'
import { Order as MysqlOrder, Voucher as MysqlVoucher } from '../mysql'

export namespace User {
  export namespace Coach {
    export const UserImportanceValues = [1, 2, 3, 4, 5] as const
    export type UserImportance = (typeof UserImportanceValues)[number]

    export type ActivityImages = {
      workout?: {
        barbell?: number
        bodyweight?: number
        bosu?: number
        dumbbell?: number
        foamroller?: number
        gym?: number
        kettlebell?: number
        medicineball?: number
        pullupbar?: number
        resistanceband?: number
        swissball?: number
        trx?: number
      }
      warmup?: number
      recovery?: number
      custom?: number
    }
  }

  export interface Coach {
    zendesk_request_id?: string
    coach_id?: string
    notes_general?: string
    notes_time_sensitive?: string
    calendly_invitee_uuid?: string
    calendly_invitee_full_name?: string
    calendly_invitee_email?: string
    calendly_event_start_time?: FirestoreTimestamp
    calendly_event_end_time?: FirestoreTimestamp
    selected_coach_id?: string
    top_match_coach_id?: string
    activity_images?: Coach.ActivityImages
    ai_coach_helper_request_count?: number
    user_display_name?: string
    user_importance?: Coach.UserImportance
    last_sent_at?: FirestoreTimestamp
  }

  export namespace Achievements {
    export type Achievement = {
      progress?: number
      achieved?: FirestoreTimestamp
    }

    export type DigitalAchievements = Record<MountainKeys, Achievement>

    export type YogaAchievements = Record<FlowerKeys, Achievement>

    export type MountainKeys =
      | 'basecamp'
      | 'kirkjufell'
      | 'el_capitan'
      | 'olympus'
      | 'fitz_roy'
      | 'aoraki'
      | 'fuji'
      | 'matterhorn'
      | 'mont_blanc'
      | 'popocatepetl'
      | 'kilimanjaro'
      | 'denali'
      | 'annapurna'
      | 'k2'
      | 'everest'
      | 'moon'

    export type FlowerKeys =
      | 'camellia'
      | 'cherry_blossom'
      | 'chrysanthemum'
      | 'clematis'
      | 'fuchsia'
      | 'dahlia'
      | 'frangipani'
      | 'heliconia'
      | 'hibiscus'
      | 'hydrangea'
      | 'jasmine'
      | 'liliac'
      | 'lily'
      | 'lisianthus'
      | 'magnolia'
      | 'morning_glory'
      | 'orchid'
      | 'peony'
      | 'poppy'
      | 'rose'
      | 'torch_ginger'
      | 'tulip'
      | 'vanilla'
      | 'wind_flower'

    export type Codes = MountainKeys | FlowerKeys
  }

  export namespace Auth {
    export enum SignInMethod {
      APPLE = 'apple.com',
      PASSWORD = 'password',
      GOOGLE = 'google.com',
      FACEBOOK = 'facebook.com',
      CUSTOM_HUAWEI = 'custom_huawei',
    }
  }

  export type Locale = {
    country: string
    language: string
    timezone: string
  }

  export interface OnboardingCompleted {
    workouts?: boolean
    hc?: boolean
    hc_second?: boolean
    nutrition?: boolean
    yoga?: boolean
    breathwork?: boolean
  }

  export type EmailScheduleItem = {
    trigger_event: 'signup' | 'subscription_expired'
    target_coach?: boolean
    target_verified?: boolean
    campaign?: string
    trigger_offset_days?: number
    trigger_time_hours?: number
    trigger_offset_minutes?: number
    template: string
    target_free: boolean
    cta_target: string
    promo?: string
    countdown_duration_hours?: number
    from?: string
  }

  export type PromoScheduleItem = {
    title: string
    message: string
    promo: string
    promo_discount_percentage?: string
    trigger_event: 'signup' | 'subscription_expired'
    trigger_offset_days?: number
    trigger_time_hours?: number
    trigger_offset_minutes?: number
  }

  export type NotificationScheduleItem = {
    title: string
    message: string
    trigger_event: 'signup' | 'first_open'
    trigger_offset_days?: number
    trigger_time_hours?: number
    trigger_offset_minutes?: number
    target_finished_workouts?: number
    target_nonregistered?: boolean
  }

  export type Notifications = {
    enabled: boolean
    days: Profile.WorkoutDays[]
    time: string
  }

  export type Ability = {
    cardio: number
    flexibility: number
    strength: number
  }

  export type PlanSettings = {
    workouts_per_week: number
    recovery_per_week: number
    warmup_duration: number
    workout_duration: number
    recovery_duration: number
    workout_days?: Profile.WorkoutDays[]
  }

  export type PlanProgress = {
    code?: string
    week: number
    week_day: number
    plan_id: number
    plan_day: number
  }

  export type Plan = { progress?: PlanProgress; settings?: PlanSettings }

  export type Features = {
    human_coaching?: boolean
    ai_coach?: boolean
    ai_coach_property_change?: boolean
  }

  export type PrismLabsConfig = {
    terms_version?: string
    token?: string
  }

  export type Stats = {
    session_count: number
  }

  export namespace Profile {
    export enum Gender {
      Male = 'm',
      Female = 'f',
      NonBinary = 'n',
      Unknown = 'u',
    }

    export enum ProblemArea {
      Arms = 'arms',
      Chest = 'chest',
      Belly = 'belly',
      Thighs = 'thighs',
      Butt = 'butt',
    }

    export enum TypicalDay {
      Unknown = 0,
      Seated = 1,
      OnFoot = 2,
      Active = 3,
      Inactive = 4,
    }

    export enum BadHabit {
      Sleep = 'sleep',
      Sweet = 'sweet',
      FastFood = 'fastfood',
      SugaryDrinks = 'sugary_drinks',
      NightMeal = 'night_meal',
    }

    export enum Motivation {
      FeelConfident = 'feel_confident',
      BeActive = 'be_active',
      ImproveSleep = 'improve_sleep',
      FellHappy = 'feel_happy',
      BoostImmunity = 'boost_immunity',
      BoostEnergy = 'boost_energy',
    }

    export enum Diet {
      None = 'none',
      Vegetarian = 'vegetarian',
      Vegan = 'vegan',
      Pescetarian = 'pescetarian',
    }

    export enum ExcludedIngredients {
      Dairy = 'dairy',
      Gluten = 'gluten',
      Eggs = 'eggs',
      Fish = 'fish',
      Nuts = 'nuts',
    }

    export enum FavoriteIngredients {
      Broccoli = 'broccoli',
      Mushrooms = 'mushrooms',
      Peas = 'peas',
      Zucchini = 'zucchini',
      SweetPotato = 'sweet_potato',
      Tomato = 'tomato',
      Spinach = 'spinach',
      Pepper = 'pepper',
    }

    export enum CookingPreferences {
      Fast = 'fast',
      Easy = 'easy',
      InExpensive = 'inexpensive',
      ToGo = 'to_go',
      Foodie = 'foodie',
    }

    export enum Goals {
      LoseWeight = 1,
      GetFit = 2,
      GetToned = 3,
      // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
      GainMuscle = 3,
    }

    export enum HcGoals {
      LoseWeight = 'lose_weight',
      GetToned = 'get_toned',
      GetActive = 'get_active',
      FellBetter = 'feel_better',
      EnhanceMobility = 'enhance_mobility',
      BuildStrength = 'build_strength',
      SportsPerformance = 'sports_performance',
      RecoveryInjury = 'recovery_injury',
      GetActiveFeelBetter = 'get_active_feel_better',
      Other = 'other',
    }

    export enum WorkoutEnvironment {
      AtHome = 0,
      AtTheGym = 1,
      StudioClasses = 2,
      Outdoor = 3,
    }

    export enum LoudExercises {
      DoNotExclude = 0,
      Limit = 1,
      Exclude = 2,
    }

    export enum HcWorkoutEnvironments {
      Gym = 'gym',
      Home = 'home',
      Studio = 'studio_classes',
      Outdoors = 'outdoors',
      Other = 'other',
    }

    export enum HcWhyFailed {
      DidnotEnjoy = 'didnot_enjoy',
      DidnotFit = 'didnot_fit',
      LostMotivation = 'lost_motivation',
      NoChallenge = 'no_challenge',
      TooBusy = 'too_busy',
      LackOfSupport = 'lack_of_support',
      Other = 'other',
    }

    export enum HcMotivation {
      ImproveHealth = 'improve_health',
      LookBetterConfidence = 'look_better_confidence',
      EnergizedProductive = 'energized_productive',
      Wellbeing = 'wellbeing',
      FeelBetter = 'feel_better',
      Other = 'other',
    }

    export enum HcCoachSkillsFocus {
      WeightManagement = 'weight_management',
      EatingHealthier = 'eating_healthier',
      GeneralHealth = 'general_health',
      StrengthTraining = 'strength_training',
      InjuryPrevention = 'injury_prevention',
      SeniorFitness = 'senior_fitness',
      PrenatalPostpartum = 'prenatal_postpartum',
      SportsPsychology = 'sports_psychology',
      MentalWellness = 'mentalwellness_lifecoaching',
    }

    export enum HcCoachGender {
      Male = 'm',
      Female = 'f',
      Unknown = 'u',
    }

    export enum HcCoachingStyle {
      HighEnergy = 'high_energy',
      ToughChallenging = 'tough_challenging',
      AnalyticalResultOriented = 'analytical_resultoriented',
      AlwaysFun = 'always_fun',
      CalmPositive = 'calm_positive',
      DirectLowtouch = 'direct_lowtouch',
      CustomizeTraining = 'customize_training',
    }

    // TODO: Unifiy with FitifyPlatform type
    export enum DeviceOS {
      Android = 'android',
      Ios = 'ios',
    }

    export enum YogaGoals {
      ReduceBackpain = 'reduce_backpain',
      ReduceStress = 'reduce_stress',
      Flexibility = 'flexibility',
      LoseWeight = 'lose_weight',
      Focus = 'focus',
      Energy = 'energy',
      BurnFat = 'burn_fat',
      Libido = 'libido',
      Strength = 'strength',
    }

    export enum AiCoachFocus {
      WeightLoss = 'weight_loss',
      MuscleStrength = 'muscle_strength',
      EatingHealthier = 'eating_healthier',
      SleepRecovery = 'sleep_recovery',
      MentalWellnessLife = 'mental_wellness_life',
      LongevityBioHacking = 'longevity_bio_hacking',
      RunningEndurance = 'running_endurance',
      NutritionSupplement = 'nutrition_supplement',
      GeneralFitness = 'general_fitness',
      Sports = 'sports',
    }

    export enum AiCoachStyle {
      Energy = 'energy',
      Tough = 'tough',
      Analytical = 'analytical',
      Fun = 'fun',
      CalmPositive = 'calm_positive',
      DirectLowTouch = 'direct_low_touch',
    }

    export enum LifeEvent {
      Wedding = 'wedding',
      Vacation = 'vacation',
      Sports = 'sports',
      Birthday = 'birthday',
      Reunion = 'reunion',
      FamilyGathering = 'family_gathering',
      Anniversary = 'anniversary',
      Other = 'other',
      NoEvent = 'no_event',
    }

    export enum LifeEventPeriod {
      NotSure = 'not_sure',
      MonthLess = 'month_less',
      ThreeMonthsLess = '3_months_less',
      SixMonthsLess = '6_months_less',
      SixMonthsMore = '6_months_more',
    }

    export enum BreathworkExperience {
      Beginner = 'beginner',
      SomeExperience = 'some_experience',
      RegularPractice = 'regular_practice',
      Advanced = 'advanced',
    }

    export enum BreathworkFrequency {
      Daily = 'daily',
      FewTimesAWeek = 'few_times_a_week',
      OnceAWeek = 'once_a_week',
      Occasionally = 'occasionally',
    }

    export enum BreathworkGoals {
      BoostEnergy = 'boost_energy',
      WakeUpEasier = 'wake_up_easier',
      ImproveFocus = 'improve_focus',
      ReduceAnxiety = 'reduce_anxiety',
      BecomeMorePresent = 'become_more_present',
      IncreaseSportsPerformance = 'increase_sports_performance',
      BeHappier = 'be_happier',
      FallAsleepFaster = 'fall_asleep_faster',
      SleepBetter = 'sleep_better',
      Other = 'other',
    }

    export enum BreathworkLocations {
      AtHome = 'at_home',
      InTheOffice = 'in_the_office',
      Outdoors = 'outdoors',
      Anywhere = 'anywhere',
    }

    export enum BreathworkChallenges {
      TroubleFallingAsleep = 'trouble_falling_asleep',
      Anxiety = 'anxiety',
      LowSportsPerformance = 'low_sports_performance',
      FatigueDuringTheDay = 'fatigue_during_the_day',
      SadnessAndBadMoods = 'sadness_and_bad_moods',
      LowEnergyInTheMorning = 'low_energy_in_the_morning',
      DifficultyConcentrating = 'difficulty_concentrating',
      NotBeingPresent = 'not_being_present',
      BadImmunity = 'bad_immunity',
    }

    export enum HcRatingLevel {
      VeryGood = 'very_good',
      QuiteGood = 'quite_good',
      QuiteBad = 'quite_bad',
      VeryBad = 'very_bad',
    }

    export enum HcHealthIssues {
      LowerBack = 'lower_back',
      Knees = 'knees',
      HipJoints = 'hip_joints',
      Shoulders = 'shoulders',
      Elbows = 'elbows',
      Ankles = 'ankles',
      Wrists = 'wrists',
      Other = 'other',
    }

    export enum HcOtherActivities {
      Running = 'running',
      Walking = 'walking',
      Yoga = 'yoga',
      Cycling = 'cycling',
      OrganizedSports = 'organized_sports',
      Mindfulness = 'mindfulness',
      Other = 'other',
    }

    export enum HcEndurance {
      Low = 'low',
      Moderate = 'moderate',
      Average = 'average',
      High = 'high',
      Athletic = 'athletic',
    }

    export enum HcWhereGym {
      LargeGym = 'large_gym',
      SmallGym = 'small_gym',
      GarageGym = 'garage_gym',
      HomeGym = 'home_gym',
      NoEquipment = 'no_equipment',
    }

    export enum HcWeightTrainingExperience {
      Novice = 'novice',
      Beginner = 'beginner',
      Medium = 'medium',
      Experienced = 'experienced',
    }

    export enum HcSchedule {
      Flexible = 'flexible',
      Open = 'open',
      Busy = 'busy',
      NoTime = 'no_time',
    }

    export enum HcWorkoutLength {
      Short = 'short',
      Medium = 'medium',
      Long = 'long',
    }

    export enum WorkoutDays {
      Sunday = 0,
      Monday = 1,
      Tuesday = 2,
      Wednesday = 3,
      Thursday = 4,
      Friday = 5,
      Saturday = 6,
    }

    export enum MaxImpact {
      QuiteSerious = 0,
      MildPain = 1,
      NoProblems = 2,
    }

    export enum PreviousExperience {
      Unknown = 0, // Android only
      WorkoutRegularly = 1,
      MoreThanYearAgo = 2,
      LessThanYearAgo = 3,
      None = 4,
    }

    export enum PlanPace {
      Unknown = 0,
      Fast = 1,
      Slow = 2,
      Normal = 3,
    }

    export enum WorkoutLength {
      Short = 1,
      Medium = 2,
      Long = 3,
    }

    export enum BodyType {
      // Male
      Skinny = 1,
      Ideal = 2,
      Flabby = 3,
      Heavier = 4,
      // Female
      // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
      Hourglass = 1,
      // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
      Rectangle = 2,
      // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
      Rounded = 3,
      // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
      Lightbulb = 4,
    }

    export enum Fitness {
      NotVeryFit = 1,
      Fit = 2,
      VeryFit = 3,
    }

    export enum WorkoutFrequency {
      Never = 0,
      OneTwo = 1,
      ThreeFour = 2,
      MoreThanFour = 3,
    }

    export enum PushUpCount {
      AlmostNone = 0,
      Few = 1,
      Some = 2,
      Many = 3,
    }

    export enum WalkingDuration {
      LessThanOneHour = 0,
      OneTwoHours = 1,
      MoreThanTwoHours = 2,
    }

    export enum SleepDuration {
      MoreThanEight = 0,
      SevenToEight = 1,
      SixToSeven = 2,
      LessThanSix = 3,
    }

    export enum Stress {
      Daily = 1,
      Weekly = 2,
      NotMuch = 3,
    }

    export enum WaterIntake {
      None = 0,
      Two = 1,
      ThreeToSix = 2,
      MoreThanSix = 3,
    }

    export enum EnergyLevel {
      Stable = 0,
      LunchtimeTired = 1,
      NapAfterMeals = 2,
    }

    export enum LatestIdealWeight {
      LessThanOne = 0,
      OneToTwo = 1,
      MoreThanThree = 2,
      Never = 3,
    }

    export enum MotivationLevel {
      NotMuch = 1,
      Motivated = 2,
      VeryMotivated = 3,
    }

    export enum Commitment {
      AtLeast1Month = 1,
      AtLeast3Months = 2,
      AtLeast12Months = 3,
    }

    export enum HcWorkoutsPerWeek {
      One = 1,
      Two = 2,
      Three = 3,
      Four = 4,
      Five = 5,
      SixSeven = 6,
    }

    export enum HcTypicalDay {
      Unknown = 0,
      Seated = 1,
      OnFoot = 2,
      Active = 3,
      Inactive = 4,
      Other = 5,
    }

    export enum HcLifestyleHabits {
      LotOfWork = 1,
      Healthy = 2,
      HealthyLife = 3,
      NoImprovement = 4,
    }

    export enum HcLastIdealShape {
      Unknown = -1,
      Now = 0,
      OneSixMonths = 1,
      SixTwelveMonths = 2,
      OneTwoYears = 3,
      MoreThanTwoYears = 4,
      Never = 5,
    }

    export enum HcCommitment {
      JustTesting = 0,
      AtLeast3Months = 2,
      AtLeast12Months = 3,
    }
  }

  export interface Profile {
    gender?: Profile.Gender
    goal?: Profile.Goals
    fitness?: Profile.Fitness
    birthday?: string
    height?: number
    weight?: number
    units?: UnitSystem
    newsletter?: boolean
    max_impact?: Profile.MaxImpact
    body_type?: Profile.BodyType
    problem_areas?: Profile.ProblemArea[]
    goal_weight?: number
    typical_day?: Profile.TypicalDay
    plan_pace?: Profile.PlanPace
    workout_frequency?: Profile.WorkoutFrequency
    push_up_count?: Profile.PushUpCount
    energy_level?: Profile.EnergyLevel
    latest_ideal_weight?: Profile.LatestIdealWeight
    water_intake?: Profile.WaterIntake
    walking_duration?: Profile.WalkingDuration
    sleep_duration?: Profile.SleepDuration
    bad_habits?: Profile.BadHabit[]
    previous_experience?: Profile.PreviousExperience
    stress?: Profile.Stress
    motivation?: Profile.Motivation[]
    motivation_level?: Profile.MotivationLevel
    commitment?: Profile.Commitment
    diet?: Profile.Diet
    excluded_ingredients?: Profile.ExcludedIngredients[]
    favorite_ingredients?: Profile.FavoriteIngredients[]
    cooking_preferences?: Profile.CookingPreferences[]
    fitness_time_consuming?: boolean
    confident?: boolean
    procrastination?: boolean
    feel_support?: boolean
    hc_goals?: Profile.HcGoals[]
    hc_last_ideal_shape?: Profile.HcLastIdealShape
    hc_typical_day?: Profile.HcTypicalDay
    hc_lifestyle_habits?: Profile.HcLifestyleHabits
    hc_commitment?: Profile.HcCommitment
    hc_workouts_per_week?: Profile.HcWorkoutsPerWeek
    hc_workout_environment?: Profile.HcWorkoutEnvironments[]
    hc_why_failed?: Profile.HcWhyFailed[]
    hc_motivation?: Profile.HcMotivation[]
    hc_coach_skills_focus?: Profile.HcCoachSkillsFocus[]
    hc_coach_gender?: Profile.HcCoachGender
    hc_coaching_style?: Profile.HcCoachingStyle[]
    device_os?: Profile.DeviceOS
    device_apple_watch?: boolean
    yoga_goals?: Profile.YogaGoals[]
    yoga_previous_experience?: number
    yoga_workout_frequency?: number
    better_shape?: boolean
    love_body?: boolean
    feel_motivated?: boolean
    achieving_goals?: boolean
    workout_environment?: Profile.WorkoutEnvironment[]
    loud_exercises?: Profile.LoudExercises
    workout_length?: Profile.WorkoutLength
    accessible_tools?: string[]
    leading_sleep_better?: boolean
    leading_improve_health?: boolean
    leading_more_relaxed?: boolean
    ai_coach_focus?: Profile.AiCoachFocus[]
    ai_coach_style?: Profile.AiCoachStyle[]
    life_event?: Profile.LifeEvent
    life_event_period?: Profile.LifeEventPeriod
    breathwork_experience?: Profile.BreathworkExperience
    breathwork_frequency?: Profile.BreathworkFrequency
    breathwork_goals?: Profile.BreathworkGoals[]
    breathwork_locations?: Profile.BreathworkLocations[]
    breathwork_challenges?: Profile.BreathworkChallenges[]
    hc_sleep?: Profile.HcRatingLevel
    hc_stress?: Profile.HcRatingLevel
    hc_diet?: Profile.HcRatingLevel
    hc_health_issues?: Profile.HcHealthIssues[]
    hc_other_activities?: Profile.HcOtherActivities[]
    hc_endurance?: Profile.HcEndurance
    hc_where_gym?: Profile.HcWhereGym[]
    hc_weight_training_experience?: Profile.HcWeightTrainingExperience
    hc_schedule?: Profile.HcSchedule
    hc_workout_length?: Profile.HcWorkoutLength
    hc_workout_days?: Profile.WorkoutDays[]
  }

  export namespace RegistrationToken {
    export enum Permission {
      NotDetermined = 'not_determined',
      Denied = 'denied',
      Authorized = 'authorized',
      Provisional = 'provisional',
      Ephemeral = 'ephemeral',
      Enabled = 'enabled',
      NotEnabled = 'not_enabled',
    }
  }

  export type RegistrationToken = {
    app: FitifyApp
    app_version_code?: number
    app_version_name?: string
    appsflyer_id?: string
    device_manufacturer?: string
    device_model?: string
    att?: number
    fid?: string
    gaid?: string
    idfa?: string
    os_version?: string
    permission?: RegistrationToken.Permission
    platform: FitifyPlatform
    timestamp: FirestoreTimestamp
    token: string
  }

  export type Order = Pick<
    MysqlOrder,
    | 'cancellation_time'
    | 'create_time'
    | 'expiry_time'
    | 'gateway'
    | 'order_id'
    | 'payment_state'
    | 'price_amount_micros'
    | 'price_currency_code'
    | 'product_id'
    | 'purchase_type'
    | 'revoked'
    | 'revocation_time'
  >

  export namespace Order {
    export enum PaymentState {
      Pending = 0,
      Received = 1,
      FreeTrial = 2,
    }
  }

  export type Voucher = Omit<MysqlVoucher, 'id' | 'user_id'>

  export type Attribution = {
    user_id: string
    source: string
    medium: string
    name: string
    adgroup: string
    keyword: string
    creativeset: string
  }

  export namespace Subscription {
    export enum FirestoreFieldName {
      Workouts = 'subscription',
      Yoga = 'subscription_yoga',
      Hc = 'subscription_hc',
      BodyScanning = 'subscription_body_scanning',
    }

    export enum Gateway {
      AppStore = 'appstore',
      Fastspring = 'fastspring',
      GooglePlay = 'google_play',
      Huawei = 'huawei',
      Paddle = 'paddle',
    }

    export enum SkuPrefix {
      FITIFY_HC = 'fitify.hc',
      FITIFY_BODYSCANNING = 'fitify.bodyscanning',
      HC_PLUS = 'hc.plus',
      YOGA_PRO = 'yoga.pro',
      INTERNAL_FREE = 'fitify.pro.free', // for internal use only https://fitify.atlassian.net/wiki/spaces/FIT/pages/4818247/Plans+and+Pricing#Testing-SKU
    }

    export enum State {
      Active = 'active',
      Canceled = 'canceled',
      Expired = 'expired',
    }

    export enum VoucherProductId {
      Workouts = 'fitify.pro.voucher',
      Hc = 'fitify.hc.voucher',
    }
  }

  export interface Subscription {
    active?: boolean
    product_id: string
    expires?: FirestoreTimestamp | number
    created: FirestoreTimestamp
    canceled?: FirestoreTimestamp
    gateway?: string
  }

  export type AuthData = {
    uid: string
    email?: string
    emailVerified: boolean
    displayName?: string
    photoURL?: string
    disabled: boolean
    metadata: AuthData.Metadata
    providerData: AuthData.ProviderData[]
    customClaims?: AuthData.CustomClaims

    tokensValidAfterTime?: string
  }

  export namespace AuthData {
    export type Metadata = {
      lastSignInTime: string
      creationTime: string
    }

    export type ProviderData = {
      uid: string
      displayName: string
      email: string
      photoURL: string
      providerId: string
    }

    export type CustomClaims = {
      role?: CustomClaims.Role
    }

    export namespace CustomClaims {
      export enum Role {
        User = 'user',
        Admin = 'admin',
        Coach = 'coach',
      }
    }
  }

  export type AiCoach = {
    ai_coach_id?: FirestoreDocumentId
    last_read_at?: FirestoreTimestamp
    conversation_context?: ConversationContextMessage[]
    first_message_timestamp: FirestoreTimestamp
  }

  export type AiMealScan = {
    conversation_context?: ConversationContextMessage[]
    first_message_timestamp: FirestoreTimestamp
  }

  export namespace BodyScans {
    export type Measurements = {
      neck_fit: number
      shoulder_fit: number
      upper_chest_fit: number
      chest_fit: number
      lower_chest_fit: number
      waist_fit: number
      waist_navy_fit: number
      stomach_fit: number
      hips_fit: number
      upper_thigh_left_fit: number
      upper_thigh_right_fit: number
      thigh_left_fit: number
      thigh_right_fit: number
      lower_thigh_left_fit: number
      lower_thigh_right_fit: number
      calf_left_fit: number
      calf_right_fit: number
      ankle_left_fit: number
      ankle_right_fit: number
      mid_arm_right_fit: number
      mid_arm_left_fit: number
      lower_arm_right_fit: number
      lower_arm_left_fit: number
      waist_to_hip_ratio: number
    }

    export type BodyComposition = {
      bodyfat_percentage: number
      lean_mass_percentage: number
      fat_mass: number
      lean_mass: number
    }

    export enum BodyFatMethod {
      Adam = 'adam',
      Army = 'army',
      Coco = 'coco',
      TinaFit = 'tina_fit',
      ExtendedNavyThinBoost = 'extended_navy_thinboost',
    }

    export enum ScanResult {
      Created = 'created',
      Processing = 'processing',
      Ready = 'ready',
      Failed = 'failed',
    }

    export type Scan = {
      device_config_name: string
      bodyfat_method: BodyFatMethod
      sex: 'male' | 'female' | 'neutral'
      birth_date: Timestamp
      weight: number
      height: number
      status: ScanResult
      measurements?: Measurements
      body_composition?: BodyComposition
      created_at: Timestamp
      updated_at: Timestamp
    }
  }

  /**
   * TODO: Remove deprecated tags once Zendesk support is dropped
   */
  export enum HcTags {
    /** @deprecated */
    CallNotScheduled = 'call_not_scheduled',
    /** @deprecated */
    OfferIntroCall = 'offer_intro_call',
    NotificationsNotEnabled = 'notifications_not_enabled',
    NoMessageSent = 'no_message_sent',
    SecondOnboardingCompleted = 'second_onboarding_completed',
    /** @deprecated */
    NoWorkouts = 'no_workouts',
    NoWorkoutsFinalized = 'no_workouts_finalized',
    /** @deprecated */
    CurrentWeekNoWorkouts = 'current_week_no_workouts',
    CurrentWeekNoWorkoutsFinalized = 'current_week_no_workouts_finalized',
    NextWeekNoWorkouts = 'next_week_no_workouts',
    NextWeekNoWorkoutsFinalized = 'next_week_no_workouts_finalized',
    LastWorkoutMissed = 'last_workout_missed',
    /** @deprecated */
    Inactive7d = 'inactive_7d',
    UserNotLoggedIn = 'user_not_logged_in',
    UserNeverLoggedIn = 'user_never_logged_in',
    /** @deprecated */
    SubscriptionActive = 'subscription_active',
    /** @deprecated */
    SubscriptionCanceled = 'subscription_canceled',
    /** @deprecated */
    SubscriptionExpired = 'subscription_expired',
  }

  export type Isic = {
    cardholder_name: string
    card_number: string
    card_type: 'ISIC' | 'ITIC'
    discount_id: string
    last_verified: FirestoreTimestamp | null
    verification_id: number
  }
}

export interface User {
  ai_coach?: User.AiCoach
  ai_meal_scan?: User.AiMealScan
  ability?: User.Ability
  profile?: User.Profile
  plan?: User.Plan
  subscription_hc?: User.Subscription
  subscription_body_scanning?: User.Subscription
  subscription?: User.Subscription
  subscription_yoga?: User.Subscription
  coach?: User.Coach
  locale?: User.Locale
  features?: User.Features
  achievements?: Partial<User.Achievements.DigitalAchievements>
  achievements_yoga?: Partial<User.Achievements.YogaAchievements>
  onboarding_completed?: User.OnboardingCompleted
  email_schedule?: User.EmailScheduleItem[]
  promo_schedule?: User.PromoScheduleItem[]
  notification_schedule?: User.NotificationScheduleItem[]
  notifications?: User.Notifications
  registered?: FirestoreTimestamp
  stats?: User.Stats
  last_sent_at?: FirestoreTimestamp
  last_hc_app_login?: FirestoreTimestamp
  hc_tags?: User.HcTags[]
  isic?: User.Isic
  prism_labs_config?: User.PrismLabsConfig
}
