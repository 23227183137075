import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArrowSmLeftIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9.333 4.89.943.943L7.609 8.5l2.667 2.667-.943.942-3.138-3.138a.666.666 0 0 1 0-.942L9.333 4.89Z"
    />
  </StyledIcon>
)
