import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArrowSmRightIcon = (props: IconProps) => (
  <StyledIcon
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.66666 12.1093L5.724 11.1667L8.39066 8.49999L5.724 5.83333L6.66666 4.89066L9.80466 8.02866C10.0653 8.28933 10.0653 8.71066 9.80466 8.97133L6.66666 12.1093Z"
    />
  </StyledIcon>
)
