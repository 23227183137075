import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const CaloriesIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2568 0.530983C16.3487 2.83036 20.5738 8.27292 20.5738 14.7131C20.5738 20.3758 15.6922 23.5 11.7869 23.5C7.88162 23.5 3 20.3758 3 14.7131C3 11.1549 5.05972 8.33816 7.24145 7.19C7.54261 7.03152 7.88544 7.26388 7.90913 7.60337C8.00848 9.02678 8.37691 11.1946 9.01443 12.3088C9.16186 12.5665 9.50522 12.5472 9.64724 12.2865C10.89 10.0051 11.3364 4.8216 9.62589 1.32242C9.41947 0.900145 9.8171 0.364999 10.2568 0.530983ZM12.4198 13.7594C14.035 14.6163 15.243 16.2026 15.243 18.2334C15.243 20.369 13.3338 21.5472 11.8064 21.5472C10.279 21.5472 8.36978 20.369 8.36978 18.2334C8.36978 17.688 8.54163 16.649 9.26396 16.2032C9.38183 16.1305 9.52289 16.2274 9.56092 16.3606C9.69367 16.8254 10.1414 17.2994 10.7977 17.4826C10.8329 17.4925 10.8704 17.4915 10.905 17.4796C12.0318 17.0921 12.5977 15.5733 12.1379 14.0147C12.0868 13.8412 12.26 13.6746 12.4198 13.7594Z"
      fill="currentColor"
    />
  </StyledIcon>
)
