import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const MagnifierIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M8.33333 7.33333h-.52666l-.18667-.18c.65333-.76 1.04667-1.74666 1.04667-2.82C8.66667 1.94 6.72667 0 4.33333 0 1.94 0 0 1.94 0 4.33333c0 2.39334 1.94 4.33334 4.33333 4.33334 1.07334 0 2.06-.39334 2.82-1.04667l.18.18667v.52666L10.6667 11.66l.9933-.9933-3.32667-3.33337Zm-4 0c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3Z"
      fill="currentColor"
    />
  </StyledIcon>
)
