import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const CheckIcon = (props: IconProps) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8.5 19.9999C8.30298 20 8.10788 19.9613 7.92583 19.8859C7.74378 19.8106 7.57835 19.7002 7.439 19.5609L0.378998 12.4999L2.5 10.3789L8.5 16.3789L21.5 3.37891L23.621 5.49991L9.561 19.5609C9.42164 19.7002 9.25622 19.8106 9.07417 19.8859C8.89212 19.9613 8.69701 20 8.5 19.9999Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
