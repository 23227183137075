import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const FilterIcon = (props: IconProps) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2.21V4.42L5 8.42L9 12.42V18.01C9 21.084 9.009 23.6 9.02 23.6C9.031 23.6 10.381 22.93 12.02 22.11L15 20.62V12.42L19 8.42L23 4.42V0H1V2.21ZM21 2.79V3.58L17 7.58L13 11.58V19.381L12.02 19.87C11.481 20.14 11.031 20.36 11.02 20.36C11.009 20.36 11 18.384 11 15.97V11.58L7 7.58L3 3.58V2H21V2.79Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
