import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const AppleWatchDisconnectedIcon = (props: IconProps) => (
  <StyledIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="3" y="2.5" width="10" height="11" rx="2" fill="#E0E0E0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.11928 6.67782c-.15597-.15506-.15597-.40646 0-.56152.15596-.15507.40883-.15507.5648 0l1.31704 1.30939 1.31639-1.30876c.15596-.15506.40883-.15506.5648 0 .15599.15506.15599.40646 0 .56153L8.56592 7.98722l1.31871 1.31106c.15597.15506.15597.40646 0 .56152-.15597.1551-.40884.1551-.5648 0L8.00112 8.54874l-1.31935 1.3117c-.15596.15506-.40883.15506-.5648 0-.15596-.15506-.15596-.40647 0-.56153l1.31934-1.31169-1.31703-1.3094Z"
      fill="#9E9E9E"
    />
    <path
      d="M10.9129 1.81493a.1547.1547 0 0 1-.0727.0184H5.18763a.15457.15457 0 0 1-.07265-.0184.1624.1624 0 0 1-.05673-.05085.17146.17146 0 0 1-.02847-.07222.17445.17445 0 0 1 .00601-.07786L5.47791.228c.02119-.06646.06186-.12425.11626-.16518.0544-.04093.11976-.0629.18679-.06282h4.46594c.067-.00009.1324.0219.1868.06282.0544.04093.0951.09872.1162.16518l.4422 1.386a.17531.17531 0 0 1 .006.07786.17163.17163 0 0 1-.0285.07222.16204.16204 0 0 1-.0567.05085ZM5.08648 14.1841a.15255.15255 0 0 1 .0714-.0175l5.65452.0027a.15258.15258 0 0 1 .0714.0175c.0222.0115.0414.0284.0562.0492a.17135.17135 0 0 1 .0284.0722.17376.17376 0 0 1-.0061.0778l-.4421 1.386c-.0212.0664-.0619.1242-.1163.1652-.0544.0409-.1197.0629-.1867.0628H5.75121c-.06702-.0005-.13218-.0231-.18623-.0645-.05406-.0414-.09426-.0996-.1149-.1662l-.44213-1.386a.17463.17463 0 0 1-.00605-.0778.17145.17145 0 0 1 .02838-.0722.16012.16012 0 0 1 .0562-.0492Z"
      fill="#E0E0E0"
    />
    <path
      d="M10.9129 1.81493a.1547.1547 0 0 1-.0727.0184H5.18763a.15457.15457 0 0 1-.07265-.0184.1624.1624 0 0 1-.05673-.05085.17146.17146 0 0 1-.02847-.07222.17445.17445 0 0 1 .00601-.07786L5.47791.228c.02119-.06646.06186-.12425.11626-.16518.0544-.04093.11976-.0629.18679-.06282h4.46594c.067-.00009.1324.0219.1868.06282.0544.04093.0951.09872.1162.16518l.4422 1.386a.17531.17531 0 0 1 .006.07786.17163.17163 0 0 1-.0285.07222.16204.16204 0 0 1-.0567.05085Z"
      fill="#E0E0E0"
    />
  </StyledIcon>
)
