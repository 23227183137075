import styled, { css } from 'styled-components'

import { colors } from '../theme/colors'

import { TextTypes } from './Text.Types'

export const baseTextVariants = {
  h1: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
  `,
  h2: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
  `,
  h3: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  `,
  h4: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
  `,
  h5: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
  `,
  h6: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
  `,
  p: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
  `,
  button: css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  `,
}

export const TextStyles = styled.p<TextTypes.StyledProps>`
  white-space: pre-wrap;

  ${(props) =>
    props.fontFamily
      ? css`
          font-family: ${props.fontFamily};
        `
      : css`
          font-family:
            Graphik LCG Web,
            sans-serif;
        `}

  ${(props) =>
    props.fontWeight
      ? css`
          font-weight: ${props.fontWeight};
        `
      : css`
          font-weight: 400;
        `}

  ${(props) =>
    props.color
      ? css`
          color: ${colors[props.color]};
        `
      : css`
          color: inherit;
        `}

  ${(props) => props.$variant && props.theme.textVariants[props.$variant]};

  strong {
    font-weight: 500;
  }

  em {
    background: #b8fa4e;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 0;
  }
`
